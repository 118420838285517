import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { FcEngineering, FcCancel } from "react-icons/fc";
// import "react-datepicker/dist/react-datepicker.css";
// import EditScladOrderListModal from "../Sclad/EditScladOrderListModal.js";
// import AddRecieptListModal from "./AddRecieptListModal.js";
// import EditRecieptListModal from "./EditRecieptListModal.js";

const CoffeeMasinsListModal = ({ id }) => {
  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [loading, seloading] = useState(false)

  const { coffeeMashins, coffeeMashinsListModal, setCoffeeMashinListsModal, editRecieptListModal, setEditRecieptListModal, addRecieptOrderListModal, setAddRecieptOrderListModal, recieptListModal, setRecieptListModal, addScladOrderListItemModal, setAddScladOrderListItemModal, editScladOrderListItemModal, setReciepts, scladOrderListModal, setScladOrderListModal, setShops, admin, setSclad, setScladOrders, scladOrders } = useContext(QtdContext);
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "dacda" && setCoffeeMashinListsModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const cm = coffeeMashins.find(pr => pr.id == id)
  const counter = cm.reportsCounters.map(pr => JSON.parse(pr))
  const filt = counter.filter(pr => pr.checked)
  const sorted = filt.sort((a, b) => a.name > b.name ? 1 : -1);

  const [editItem, setEditItem] = useState({})
  const OpnaEditModal = (order_id, prod_id) => {
    setEditItem({ order_id, prod_id })

    setEditRecieptListModal(true)
  }
  // const OpnaAddModal = ( e) =>{
  //   // setEditItem(order_id )

  //   setEditScladOrderListItemModal(true)
  // }
  const deletIt = async (order_id, prod_id) => {
    try {
      seloading(true)
      const newProd = await $api.post(`${BACKURL}/api/reciepts/deleteitem`,
        { order_id, prod_id, admin: admin.id })

      setReciepts(
        newProd.data
      )
      seloading(false)

      setCoffeeMashinListsModal(false)

    } catch (e) {
      seloading(false)

      // console.log(e)
    }
  }
  return (
    <>
      {coffeeMashinsListModal && (
        <div onClick={closeModal2} id="dacda" className={style.modal_container}>

<div className={style.borders} style={{height:'80%'}}>
            {/* {status == "Передан на склад!" && ( */}
            {/* <Button
              text="Добавить товар"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={() => setAddRecieptOrderListModal(true)
              } */}
            {/* /> */}
            {/* )} */}


            {/* <div className={style.inputtest} style={'margin'}> */}

            <div className={style.inputtest}>
              <h5>Счетчики</h5>
              <div className={style.shopsdiv} style={{ flexDirection: 'column' }}>

                <br></br>
                {sorted.map((shop, index) => (
                  <>
                    <div className={style.coffees}>
                      <div className={style.form_radio_btn}
                      // onClick={() => handleRadioChange(shop.id)}
                      >
                        <label htmlFor={`radio_${shop.id}`}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                        </label>
                        <input type="radio" name="radio_group"

                          checked={shop.checked}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                        // className={style.form_radio_btn}
                        />
                      </div>
                    </div>

                    {shop.checked && (<>
                      <label > Введите показание счетчика:</label>

                      <input type="text" id="pass" name="pass"
                        value={shop.counter}
                      // onChange={(event) => CounterChange(shop.id, event.target.value)
                      // }
                      />
                      {/* {err.fullname && (
                        <p style={{ color: "red" }}>{err.fullname}</p>
                      )} */}
                    </>
                    )}
                  </>



                )
                )}
              </div>






            </div>




            {/* </div> */}


          </div>

        </div>
      )}
      {/* {editRecieptListModal && (
        <EditRecieptListModal
          order_id={editItem.order_id}
          prod_id={editItem.prod_id}
        ></EditRecieptListModal>
      )}
      {addRecieptOrderListModal && (
        <AddRecieptListModal
          order_id={order_id}
        ></AddRecieptListModal>
      )} */}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default CoffeeMasinsListModal;
