import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import scladShops from "../../../../../citycoffee_back/src/services/scladShops.js";

const ScladInventarisationModalShop = ({ id }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [loading, setLoading] = useState(false)
  const { setSmenas, Notify, scladInventarisationModalShop, scladHistoryShops, setScladHistoryShops, scladShops, setScladInventarisationModalShop, setScladShops, setSclad, setScladHistory, scladHistory, scladInventarisationModal, setScladInventarisationModal, sclad, setScladHistoryListModal, shops, setShops, admin } = useContext(QtdContext);
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "gj22h2hkkq" && setScladInventarisationModalShop(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const sorted = sclad.sort((a, b) => a.name > b.name ? 1 : -1);

  const [newValue, setNeValue] = useState({
    admin_id: admin.id,
    shop_id: id,
    // list: '',
    prod_id: '',
    qty: '',
    step: '',
    stepQty: ''
  })
  const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory)));

  const sh = scladShops.filter(pr => (pr.shop_id === Number(id) && pr.deleted == false))
  
  console.log(sh, id)
  const uniqueKategory = Array.from(new Set(sh.map(item => item.kategory)));

  const filterd = sclad.filter(pr=>pr.kategory!='Сироп'&&pr.kategory!='Чай'&&pr.deleted==false&&pr.showed)
  const uniqueKategorySclad = Array.from(new Set(filterd.map(item => item.kategory)));
  const [inventArr, setInventArr] = useState([])
  const [inventArr2, setInventArr2] = useState([])

useEffect(()=>{
  const unique = Array.from(new Set(sh.map(item => Number(item.prod_id))));


let arr = []
for(let i = 0; i<sclad.length; i++){
  if(!unique.includes(sclad[i].id) &&sclad[i].showed){
    arr.push({
      prod_id:sclad[i].id,
      shop_id:id,
      name:sclad[i].name,
      kategory:sclad[i].kategory,
      mnt:sclad[i].mnt,
      scldqty:Number(0),
      oldValue: Number(0),
      newValue: Number(0),
    })
  }
}

setInventArr2(arr)
},[])


  const setProd = (prod_id) => {
    const thisProd = inventArr.find(item => item.prod_id == prod_id)

    const result = sclad.find(item => item.id == prod_id)
    // console.log(thisProd, !result)
    if (!result) {
      let newData= inventArr
      setNeValue({ ...newValue, prod_id: prod_id, step: thisProd.step, stepQty: thisProd.qty })

    } else {
      setErr({ prod_id: 'Данный товар уже был выбран!' })
    }
    // if()

  }

  const editInvent = (prod_id, oldValue, newValue) => {
    console.log(inventArr)

    if (newValue >= 0) {
      let product = inventArr.find(pr => pr.prod_id == prod_id)
      if (product) {
        // console.log(id, oldValue, newValue)
        setInventArr(inventArr.map(prod => {
          // console.log(prod.id, id)
          if (prod.prod_id === prod_id) {
            // console.log('est')
            return {
              ...prod,
              prod_id: prod.prod_id,
              oldValue: Number(oldValue),
              newValue: Number(newValue)
            }
          } else {
            // console.log('net')

            return prod
          }
        }))
      } else {
        // console.log(id, oldValue, newValue)

        setInventArr([...inventArr, {
          prod_id: prod_id,
          oldValue: Number(oldValue),
          newValue: Number(newValue)
        }])
      }
    } else {
      if (inventArr.length > 0) {
        let product = inventArr.find(pr => pr.prod_id == prod_id)
        if (product) {
          setInventArr(inventArr.filter(prod => prod.prod_id != prod_id))
        }

      }
    }
  }
  const editInvent2 = (prod_id, oldValue, newValue) => {
    console.log(inventArr2)

    if (newValue >= 0) {
      let product = inventArr2.find(pr => pr.prod_id == prod_id)
      if (product) {
        // console.log(id, oldValue, newValue)
        setInventArr2(inventArr2.map(prod => {
          // console.log(prod.id, id)
          if (prod.prod_id === prod_id) {
            // console.log('est')
            return {
              ...prod,
              prod_id: prod.prod_id,
              oldValue: Number(oldValue),
              newValue: Number(newValue)
            }
          } else {
            // console.log('net')

            return prod
          }
        }))
      } else {
        // console.log(id, oldValue, newValue)

        setInventArr2([...inventArr2, {
          prod_id: prod_id,
          oldValue: Number(oldValue),
          newValue: Number(newValue)
        }])
      }
    } else {
      if (inventArr.length > 0) {
        let product = inventArr2.find(pr => pr.prod_id == prod_id)
        if (product) {
          setInventArr2(inventArr2.filter(prod => prod.prod_id != prod_id))
        }

      }
    }
  }


  // }
  const [err, setErr] = useState([])
  const finish = async (e) => {
    // setErr([])

    try {


      setLoading(true)

      e.preventDefault()
      const ids = inventArr.map(pr => pr.prod_id)
      const errors = sh.filter(pr => !ids.includes(pr.prod_id)

      )
      // console.log(errors.map(pr => pr.id), err, ids)
      // setErr(errors.map(pr => pr.prod_id))
      // if (errors.length > 0) {
      //   setLoading(false)

      //   // console.log('Не все продукты заполнены!')
      // } else {
        setErr([])

        // console.log('inventArr', inventArr)
        const newProd = await $api.post(`${BACKURL}/api/scladhistoryshop/inventarisation/${id}`,
          { inventArr: inventArr,inventArr2: inventArr2, admin: admin.id, shop_id: id }
        )
        setScladHistoryShops(newProd.data.scladhistory)
        setScladShops(newProd.data.sclad)
        setScladInventarisationModalShop(false)
        setSmenas(newProd.data.smenas)
        // console.log(newProd.data)
        setLoading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Инвентаризация успешно выполнена!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      // }
    } catch (x) {
      // console.log(x)
      setLoading(false)

      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })


    }
  }

  const downloadList = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      // const order = scladOrders.find(pr=>pr.id==id)
      // Отправляем запрос на сервер для скачивания Excel-файла
      const response = await $api.get(`${BACKURL}/api/xlsx/inventlist`, {
        responseType: 'blob', // Указываем, что ожидаем blob в ответе
      })
      const result1 = new Date().toLocaleDateString('en-GB');
      // Создаем ссылку для скачивания и нажимаем на нее
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `Инвентаризация склада на ${result1}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false)

    } catch (error) {
      setLoading(false)

      console.error('Error:', error);
    }
  }
  return (
    <>
      {scladInventarisationModalShop && (
        <div onClick={closeModal2} id="gj22h2hkkq" className={style.modal_container}>

          <div className={style.borders}>

            {/* <div className={style.inputtest}>

              <label >Добавить товар:</label>

              <select name="pets" id="prod"
                value={newValue.prod_id}
                onChange={(event) => {
                  setProd(event.target.value)

                }}
              >
                <option value="">--Выберите товар--</option>

                {uniqueSerials.map(serials => {

                  const table = sorted.filter(prod => (prod.kategory === serials && prod.showed))

                  return (<>
                    <optgroup label={`${serials}`}>
                      {table.map(prod => (
                        <option value={`${prod.id}`}>{prod.name}</option>

                      ))}
                    </optgroup></>
                  )
                }
                )}


              </select>
              {err.prod_id && (
                <p style={{ color: "red" }}>{err.prod_id}</p>
              )}
            </div> */}

            <p>Введите фактический остаток на складе!</p>
            {uniqueKategory.map((kategory, index) => {
              const skladkat = sh.filter(pr => pr.kategory == kategory)
              return (<div className={style.borders}>
                <h2>{kategory}</h2>
                <div className={style.table2}>
                  <table className={style.table}>
                    <thead className={style.tableth}>

                      <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>Наименование</td>
                        <td className={style.tabletd}>Склад</td>
                        <td className={style.tabletd}>Ед</td>
                        <td className={style.tabletd}>Факт</td>

                      </tr>
                    </thead>
                    <tbody>
                      {skladkat.map((prod, index2) => {
                        // const item = JSON.parse(employee)
                        // console.log(scladHistorydata, employee, item)
                        // let test = ''
                        // const value = inventArr.find(pr => pr.id == prod.id)
                        let color = 'black'
                        if (err.includes(prod.id)) {
                          color = 'red'
                        }
                        // if (value) {
                        //   test = value.value
                        // }
                        return (<tr>
                          <td className={style.tabletd} style={{ 'borderColor': color }}>{index2 + 1} </td>
                          <td className={style.tabletd} style={{ 'borderColor': color }}>{prod.name}</td>
                          <td className={style.tabletd} style={{ 'borderColor': color }}>{prod.scldqty}</td>
                          <td className={style.tabletd} style={{ 'borderColor': color }}>{prod.mnt}</td>
                          <td className={style.tabletd}>
                            {/* {!err.includes(prod.id) && ( */}
                            <input type="number"
                              // value={test}
                              style={{ 'width': '100px', 'borderColor': color }} name="pass" onChange={(event) => {
                                editInvent(prod.prod_id, prod.scldqty, event.target.value);
                              }} />
                            {/* )}
                            {err.includes(prod.id) && (
                              <input type="number" id="pass"

                                style={{ 'width': '100px', 'borderColor': 'red' }} name="pass" onChange={(event) => {
                                  editInvent(prod.id, prod.scldqty, event.target.value);
                                }} />
                            )} */}
                          </td>

                        </tr>)
                      }
                      )}



                    </tbody>
                  </table>
                </div>

              </div>)
            })}



<p>Если товара не было на складе до инвентаризации, то найдите товар ниже и заполните форму!</p>
            {uniqueKategorySclad.map((kategory, index) => {
              const skladkat = inventArr2.filter(pr => pr.kategory == kategory)
              return (<>
              {skladkat.length>0&&(
   <div className={style.borders}>
                <h2>{kategory}</h2>
                <div className={style.table2}>
                  <table className={style.table}>
                    <thead className={style.tableth}>

                      <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>Наименование</td>
                        <td className={style.tabletd}>Склад</td>
                        <td className={style.tabletd}>Ед</td>
                        <td className={style.tabletd}>Факт</td>

                      </tr>
                    </thead>
                    <tbody>
                      {skladkat.map((prod, index2) => {
                        // const item = JSON.parse(employee)
                        // console.log(scladHistorydata, employee, item)
                        // let test = ''
                        // const value = inventArr.find(pr => pr.id == prod.id)
                        let color = 'black'
                        if (err.includes(prod.id)) {
                          color = 'red'
                        }
                        // if (value) {
                        //   test = value.value
                        // }
                        return (<tr>
                          <td className={style.tabletd} style={{ 'borderColor': color }}>{index2 + 1} </td>
                          <td className={style.tabletd} style={{ 'borderColor': color }}>{prod.name}</td>
                          <td className={style.tabletd} style={{ 'borderColor': color }}>{prod.scldqty}</td>
                          <td className={style.tabletd} style={{ 'borderColor': color }}>{prod.mnt}</td>
                          <td className={style.tabletd}>
                            {/* {!err.includes(prod.id) && ( */}
                            <input type="number"
                              // value={test}
                              style={{ 'width': '100px', 'borderColor': color }} name="pass" onChange={(event) => {
                                editInvent2(prod.prod_id, prod.scldqty, event.target.value);
                              }} />
                            {/* )}
                            {err.includes(prod.id) && (
                              <input type="number" id="pass"

                                style={{ 'width': '100px', 'borderColor': 'red' }} name="pass" onChange={(event) => {
                                  editInvent(prod.id, prod.scldqty, event.target.value);
                                }} />
                            )} */}
                          </td>

                        </tr>)
                      }
                      )}



                    </tbody>
                  </table>
                </div>

              </div>
              )}
           </>
              )
            })}



            <div>
              <Button text='Завершить' background='orange'
                onClick={finish}
              />
              {err.length > 0 && (
                <p style={{ color: "red" }}>Не все остатки заполнены!</p>

              )}

            </div>



            {/* </div> */}

          </div>


        </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default ScladInventarisationModalShop;
