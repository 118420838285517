import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const ScladInventarisationModal = () => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [loading, setLoading] = useState(false)
  const { Notify,setSclad, setScladHistory, scladHistory, scladInventarisationModal, setScladInventarisationModal, sclad, setScladHistoryListModal, shops, setShops, admin } = useContext(QtdContext);
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "gjh2hkkq" && setScladInventarisationModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const uniqueKategory = Array.from(new Set(sclad.map(item => item.kategory)));

  const [inventArr, setInventArr] = useState([])
  const editInvent = (id, oldValue, newValue) => {
    if (newValue >= 0) {
      let product = inventArr.find(pr => pr.id == id)
      if (product) {
        // console.log(id, oldValue, newValue)
        setInventArr(inventArr.map(prod => {
          // console.log(prod.id, id)
          if (prod.id === id) {
            // console.log('est')
            return {
              id: prod.id,
              oldValue:  Number(oldValue),
              newValue: Number(newValue)
            }
          } else {
            // console.log('net')

            return prod
          }
        }))
      } else {
        // console.log(id, oldValue, newValue)

        setInventArr([...inventArr, {
          id: id,
          oldValue:  Number(oldValue),
          newValue:  Number(newValue)
        }])
      }
    } else {
      if (inventArr.length > 0) {
        let product = inventArr.find(pr => pr.id == id)
        if (product) {
          setInventArr(inventArr.filter(prod => prod.id != id))
        }

      }
    }
  }


  // }
  const [err, setErr] = useState([])
  const finish = async (e) => {
    // setErr([])

    try{
    setLoading(true)

    e.preventDefault()
    const ids = inventArr.map(pr => pr.id)
    const errors = sclad.filter(pr => !ids.includes(pr.id)

    )
    // console.log(errors.map(pr => pr.id), err, ids)
    setErr(errors.map(pr => pr.id))
    if (errors.length > 0) {
      setLoading(false)

      // console.log('Не все продукты заполнены!')
    } else {
      setErr([])

      // console.log('inventArr', inventArr)
      const newProd = await $api.post(`${BACKURL}/api/scladhistory/inventarisation`,
        { inventArr: inventArr, admin: admin.id }
      )
      setScladHistory([...scladHistory, newProd.data.scladhistory])
      setSclad(newProd.data.sclad)
      setScladInventarisationModal(false)
      // console.log(newProd.data)
      setLoading(false)
      Notify.addNotification({
        title: "Готово!",
        message: "Инвентаризация успешно выполнена!",
        type: "success",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  } catch(x){
    console.log(x)
    Notify.addNotification({
      title: "Ошибка!",
      message: "Произошла непредвиденная ошибка!",
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }
  }

  const downloadList = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      // const order = scladOrders.find(pr=>pr.id==id)
      // Отправляем запрос на сервер для скачивания Excel-файла
      const response = await $api.get(`${BACKURL}/api/xlsx/inventlist`, {
        responseType: 'blob', // Указываем, что ожидаем blob в ответе
      })
      const result1 = new Date().toLocaleDateString('en-GB');
      // Создаем ссылку для скачивания и нажимаем на нее
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `Инвентаризация склада на ${result1}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false)

    } catch (error) {
      setLoading(false)

      console.error('Error:', error);
    }
  }
  return (
    <>
      {scladInventarisationModal && (
        <div 
        // onClick={closeModal2}
         id="gjh2hkkq" className={style.modal_container}>

          <div className={style.borders}>
          <div>
            <Button
              text="Закрыть"
              background="var(--primary-color)"
              color="white"
              width="100%"
              onClick={() => setScladInventarisationModal(false)}
            />
          </div>
            {/* <div className={style.inputtest} style={'margin'}> */}
            {/* 
            <div className={style.table2}>
              <table className={style.table}>
                <thead className={style.tableth}>

                  <tr>
                    <td className={style.tabletd}>Наименование</td>
                    <td className={style.tabletd}>Количество</td>
                    <td className={style.tabletd}>Ед</td>

                  </tr>
                </thead>
                <tbody>
                  {scladHistorydata.map(employee => {
                    const item = JSON.parse(employee)
                    // console.log(scladHistorydata, employee, item)
                    return (<tr>
                      <td className={style.tabletd}>{item.name}</td>
                      <td className={style.tabletd}>{item.qty}</td>
                      <td className={style.tabletd}>{item.mnt}</td>

                    </tr>)
                  }
                  )}



                </tbody>
              </table>
            </div> */}
            <div>
              <Button text='Скачать инвентаризационную таблицу' background='orange'
                width='200px'
                onClick={downloadList}
              />

            </div>
            <p>Введите фактический остаток на складе!</p>
            {uniqueKategory.map((kategory, index) => {
              const skladkat = sclad.filter(pr => pr.kategory == kategory)
              return (<div className={style.borders}>
                <h2>{kategory}</h2>
                <div className={style.table2}>
                  <table className={style.table}>
                    <thead className={style.tableth}>

                      <tr>
                        <td className={style.tabletd}>№</td>
                        <td className={style.tabletd}>Наименование</td>
                        <td className={style.tabletd}>Склад</td>
                        <td className={style.tabletd}>Ед</td>
                        <td className={style.tabletd}>Факт</td>

                      </tr>
                    </thead>
                    <tbody>
                      {skladkat.map((prod, index2) => {
                        // const item = JSON.parse(employee)
                        // console.log(scladHistorydata, employee, item)
                        // let test = ''
                        // const value = inventArr.find(pr => pr.id == prod.id)
                        let color = 'black'
                        if (err.includes(prod.id)) {
                          color = 'red'
                        }
                        // if (value) {
                        //   test = value.value
                        // }
                        return (<tr>
                          <td className={style.tabletd}  style={{'borderColor': color }}>{index2 + 1} </td>
                          <td className={style.tabletd} style={{'borderColor': color }}>{prod.name}</td>
                          <td className={style.tabletd} style={{'borderColor': color }}>{prod.scldqty}</td>
                          <td className={style.tabletd} style={{'borderColor': color }}>{prod.mnt}</td>
                          <td className={style.tabletd}>
                            {/* {!err.includes(prod.id) && ( */}
                            <input type="number"
                              // value={test}
                              style={{ 'width': '100px', 'borderColor': color }} name="pass" onChange={(event) => {
                                editInvent(prod.id, prod.scldqty, event.target.value);
                              }} />
                            {/* )}
                            {err.includes(prod.id) && (
                              <input type="number" id="pass"

                                style={{ 'width': '100px', 'borderColor': 'red' }} name="pass" onChange={(event) => {
                                  editInvent(prod.id, prod.scldqty, event.target.value);
                                }} />
                            )} */}
                          </td>

                        </tr>)
                      }
                      )}



                    </tbody>
                  </table>
                </div>

              </div>)
            })}

            <div>
              <Button text='Завершить' background='orange'
                onClick={finish}
              />
              {err.length > 0 && (
                <p style={{ color: "red" }}>Не все остатки заполнены!</p>

              )}

            </div>



            {/* </div> */}

          </div>


        </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default ScladInventarisationModal;
