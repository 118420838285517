import React from "react";
import style from "./Home.module.css";
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FcCancel, FcServices } from "react-icons/fc";
import $api from '../../../http/index.ts'
import './react-confirm-alert.css'; // Import css
import { FcInfo, FcRight, FcLeft, FcUndo, FcDownload, FcDataSheet, FcFullTrash, FcEngineering } from "react-icons/fc";
// import "./Home.module.css";
import styled from "styled-components";
import { useEffect, useState, useContext } from "react";
import { RiFileDownloadLine } from "react-icons/ri";
import AddProductModal from "../../../components/Modals/Sclad/AddProductModal.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import RashoModal from "../../../components/Modals/Sclad/RashodModal.js";
import PrihodModal from "../../../components/Modals/Sclad/PrihodModal.js";
import ScladHistoryListModal from "../../../components/Modals/Sclad/ScladHistoryListModal.js";
import ScladInventarisationModal from "../../../components/Modals/Sclad/ScladInventarisationModal.js";
import EditProductModal from "../../../components/Modals/Sclad/EditProductModal.js";

const Sclad = () => {
  const { editProductModal, setEditProductModal, scladInventarisationModal, admins, setScladInventarisationModal, scladHistoryListModal, setScladHistoryListModal, scladHistory, setDashboarPage, admin, sclad, setSclad, addProductModal, setAddProductModal, setRashodModal, rashodModal, prihodModal, setPrihodModal, setAdmin
  } = useContext(QtdContext);
  // const [checked, setChecked] = React.useState(false);

  const [loading, seloading] = useState(false)
  const [ids, setids] = useState(null)
  const [itemlist, setItemlist] = useState({})
  if (!(admin.role == 'AllRights' || admin.role == 'Администратор' || admin.role == 'Доставщик' || admin.role == 'Кладовщик')) {
    setDashboarPage('Home')
    return <Navigate to="/" replace />;
  } else {

    // console.log(sclad)
    // useEffect(()=>{})
    let sorted = []
    if (admin.role == 'AllRights') {
      sorted = sclad.sort((a, b) => a.name > b.name ? 1 : -1);
    } else {
      const yy = sclad.filter(pr => pr.adminrules == true)

      sorted = yy.sort((a, b) => a.name > b.name ? 1 : -1);
    }
    // const sorted = sclad.sort((a, b) => a.name > b.name ? 1 : -1);
    const sortedHistory = scladHistory.sort((a, b) => a.id < b.id ? 1 : -1);
    const addProduct = () => {
      setAddProductModal(true)
    }
    const addRashod = () => {
      setRashodModal(true)
    }
    const addPrihod = () => {
      setPrihodModal(true)
    }
    const editPrihod = (id) => {
      setids(id)
      setEditProductModal(true)
    }

    const TCheckBox = styled.input.attrs(props => ({
      type: "checkbox"
    }))`
      display: none;
    `;
    const TCheckBoxWrapper = styled.div<{ checked: boolean }>`
      background: ${p => (p.checked ? "#3f81ff" : "#ececec")};
      border-radius: 4rem;
      height: 32px;
      width: 68px;
      position: relative;
      transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
      cursor: pointer;
    `;

    const TCheckBoxToggle = styled.div<{ checked: boolean }>`
      background: #fff;
      border-radius: 4rem;
      height: 26px;
      width: 26px;
      top: 3px;
      left: ${p => (p.checked ? "38px" : "4px")};
      position: absolute;
      transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
    `;
    const ToggleCheckbox = props => {
      return (
        <TCheckBoxWrapper checked={props.checked} onClick={props.onChange}>
          <TCheckBoxToggle checked={props.checked} />
          <TCheckBox {...props} />
        </TCheckBoxWrapper>
      );
    };
    const toggle = async (id) => {
      try {
        seloading(true)
        const deleteProd = await $api.get(`${BACKURL}/api/products/switch/${id}`)
        setSclad(sclad.map(item => item.id == id ? deleteProd.data : item))
        seloading(false)
      } catch (e) {
        console.log(e)
      }
    };
    const toggle2 = async (id) => {
      try {
        seloading(true)
        const deleteProd = await $api.get(`${BACKURL}/api/products/switchrules/${id}`)
        setSclad(sclad.map(item => item.id == id ? deleteProd.data : item))
        seloading(false)
      } catch (e) {
        console.log(e)
      }
    };
    const showList = (listik, type) => {
      // console.log(listik)
      setItemlist({ listik, type })
      setScladHistoryListModal(true)
    }

    // useEffect(())
    const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory)));
    // console.log(uniqueSerials, sclad)

    const deleteIt = async (id) => {
      try {
        seloading(true)
        const deleteProd = await $api.get(`${BACKURL}/api/products/delete/${id}`)
        setSclad(sclad.filter(item => item.id != id))
        seloading(false)
      } catch (e) {
        console.log(e)
      }
    }


    const startInv = async () => {
      setScladInventarisationModal(true)
    }

    const XLSXall = async () => {
      try {
        seloading(true)
        // const order = scladOrders.find(pr=>pr.id==id)
        // Отправляем запрос на сервер для скачивания Excel-файла
        const response = await $api.get(`${BACKURL}/api/xlsx/downloadsclad`, {
          responseType: 'blob', // Указываем, что ожидаем blob в ответе
        })
        const result1 = new Date().toLocaleDateString('en-GB');
        // Создаем ссылку для скачивания и нажимаем на нее
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `Остаток по складу на ${result1}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        seloading(false)

      } catch (error) {
        seloading(false)

        console.error('Error:', error);
      }

    }
    const submit = async (func, id) => {
      confirmAlert({
        title: 'Удалить товар',
        message: 'Вы уверены, что хотите удалить товар со склада?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id)
          },
          {
            label: 'Отменв!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };

    const downloadInvent = async (id) => {
      try {
        seloading(true)
        // const order = scladOrders.find(pr=>pr.id==id)
        // Отправляем запрос на сервер для скачивания Excel-файла
        const response = await $api.get(`${BACKURL}/api/xlsx/invent/${id}`, {
          responseType: 'blob', // Указываем, что ожидаем blob в ответе
        })
        const result1 = new Date().toLocaleDateString('en-GB');
        // Создаем ссылку для скачивания и нажимаем на нее
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `Инвентаризация склада на ${result1}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        seloading(false)

      } catch (error) {
        seloading(false)

        console.error('Error:', error);
      }

    }
    return (<>

      <div className={style.sclad_container}>
        <div>
          <Button text='Создать продукт' background='orange' onClick={addProduct} />
          <Button text='Оприходывать' background='#89f08c' onClick={addPrihod} />
          <Button text='Списать' background='#ffc6c6' onClick={addRashod} />
          <Button text='Инвентаризация' background='#fba0ff' onClick={startInv} />
          <Button text='Выгрузить' background='#fba0ff' onClick={XLSXall} />
        </div>
        <div className={style.sclad_container2}>

          <Tabs >
            <TabList>
              {uniqueSerials.map(serials => <Tab key={serials}> {serials}</Tab>)}
              {scladHistory.length > 0 && (
                <Tab key={"История"}> История</Tab>
              )}
            </TabList>

            {uniqueSerials.map(serials => {

              const table = sorted.filter(prod => prod.kategory === serials)
              return (<>
                <TabPanel>
                  <div key={`${serials}`} className={style.table2}>
                    <table className={style.table}>
                      <thead className={style.tableth}>

                        <tr>
                          <td className={style.tabletd}>№</td>
                          <td className={style.tabletd}>Наименоване</td>
                          <td className={style.tabletd}>Остаток</td>
                          <td className={style.tabletd}>Бронь</td>
                          <td className={style.tabletd}>Категория</td>
                          <td className={style.tabletd}>Кратность</td>
                          <td className={style.tabletd}>Макс</td>

                          {admin.role == 'AllRights' && (
                            <td className={style.tabletd}>AdminRules</td>
                          )}
                          <td className={style.tabletd}>on/off</td>
                          {admin.role == 'AllRights' && (
                            <td className={style.tabletd}>Действия</td>

                          )}

                        </tr>
                      </thead>
                      {table.map(prod2 =>

                        <tbody>

                          <tr>
                            <td className={style.tabletd}>{prod2.id}</td>
                            <td className={style.tabletd}>{prod2.name}</td>
                            <td className={style.tabletd}><b>{prod2.scldqty}{prod2.mnt}</b></td>
                            <td className={style.tabletd}><b>{prod2.scldbron}{prod2.mnt}</b></td>
                            <td className={style.tabletd}>{prod2.kategory}</td>
                            <td className={style.tabletd}>{prod2.step}{prod2.mnt}</td>
                            <td className={style.tabletd}>{prod2.qty * prod2.step}{prod2.mnt}</td>

                            {admin.role == 'AllRights' && (
                              <td className={style.tabletd}>

                                <div>
                                  <ToggleCheckbox checked={prod2.adminrules} onChange={() => toggle2(prod2.id)} />
                                </div>
                              </td>
                            )}
                            <td className={style.tabletd}>
                              <div>
                                <ToggleCheckbox checked={prod2.showed} onChange={() => toggle(prod2.id)} />
                              </div>
                            </td>

                            {admin.role == 'AllRights' && (
                              <td className={style.tabletd}>
                                <div className={style.cell}>

                                  <div className={style.cell}>
                                    <FcEngineering onClick={() => editPrihod(prod2.id)} />
                                  </div>
                                  <div className={style.cell}>
                                    <FcCancel onClick={() => submit(deleteIt, prod2.id)} />
                                  </div>
                                </div>
                              </td>
                            )}


                          </tr>



                        </tbody>)}
                    </table>
                  </div>
                </TabPanel>

              </>)
            })}
            {scladHistory.length > 0 && (
              <TabPanel>
                <div key={`История`} className={style.table2}>
                  <table className={style.table}>
                    <thead className={style.tableth}>

                      <tr>
                        <td className={style.tabletd}></td>
                        <td className={style.tabletd}>Дата</td>
                        <td className={style.tabletd}>Пользователь</td>
                        <td className={style.tabletd}>Тип</td>
                        <td className={style.tabletd}>Категория</td>
                        <td className={style.tabletd}>Список</td>
                        <td className={style.tabletd}>Примечание</td>
                        {/* {prod2.type == 'Инвентаризация' && ( */}
                        <td className={style.tabletd}>Накладная</td>

                        {/* )} */}
                      </tr>
                    </thead>
                    {sortedHistory.map(prod2 => {
                      // const item = 
                      const date3 = new Date(prod2.createdAt);
                      const date4 = date3.getTime()
                      const date2 = new Date(date4 + 2 * 60 * 60 * 1000)
                      // Форматирование времени в формате HH:mm
                      const time = ("0" + date2.getUTCHours()).slice(-2) + ":" + ("0" + date2.getUTCMinutes()).slice(-2);

                      // Форматирование даты в формате YYYY-MM-DD
                      const formattedDate = date2.toISOString().slice(0, 10);
                      const adm = admins.find(pr => pr.id == prod2.admin_id)
                      // Сбор итоговой строки
                      const result = time + " " + formattedDate;
                      return (<tbody>

                        <tr>

                          <td className={style.tabletd}>
                            {prod2.type === 'Инвентаризация' && (<div className={style.cell}><>
                              <FcDataSheet className={style.icon} />
                            </> </div>)}
                            {prod2.type === 'Приход' && (<div className={style.cell}><>
                              <FcLeft className={style.icon} />
                            </> </div>)}
                            {prod2.type === 'Доставка' && (<div className={style.cell}><>
                              <FcRight className={style.icon} />
                            </> </div>)}
                            {prod2.type === 'Расход' && prod2.kategory == 'Возврат' && (<div className={style.cell}><>
                              <FcUndo className={style.icon} />
                            </> </div>)}
                            {prod2.type === 'Расход' && prod2.kategory != 'Возврат' && prod2.kategory != 'Списание' && (<div className={style.cell}><>
                              <FcRight className={style.icon} />
                            </> </div>)}

                            {prod2.type === 'Расход' && prod2.kategory === 'Списание' && (<div className={style.cell}><>
                              <FcFullTrash className={style.icon} />
                            </> </div>)}
                          </td>
                          <td className={style.tabletd}>{result}</td>
                          <td className={style.tabletd}>{adm.fullname}</td>
                          <td className={style.tabletd}>{prod2.type}</td>
                          <td className={style.tabletd}>{prod2.kategory}</td>
                          <td className={style.tabletd}>
                            <FcInfo onClick={() => { showList(prod2.list, prod2.type) }} className={style.icon} />
                          </td>
                          <td className={style.tabletd}>{prod2.comment}</td>
                          {prod2.type == 'Инвентаризация' && (
                            <td className={style.tabletd}>
                              <td className={style.tabletd}>
                                <div className={style.cell}>
                                  <RiFileDownloadLine
                                    onClick={() => downloadInvent(prod2.id)}
                                  />
                                </div>
                              </td>
                            </td>

                          )}


                        </tr>



                      </tbody>)

                    }
                    )}
                  </table>
                </div>
              </TabPanel>)}
          </Tabs >
        </div>

      </div>
      {addProductModal && (
        <AddProductModal

        />

      )}
      {rashodModal && (
        <RashoModal

        />

      )}
      {prihodModal && (
        <PrihodModal

        />

      )}
      {editProductModal && (
        <EditProductModal
          id={ids}

        />

      )}
      {scladHistoryListModal && (
        <ScladHistoryListModal
          scladHistorydata={itemlist.listik}
          type={itemlist.type}

        />

      )}
      {scladInventarisationModal && (
        <ScladInventarisationModal
        />

      )}

      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}

    </>
    );
  }
};

export default Sclad;
