import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
const SaleReport = ({ id }) => {
  const { saleReportShow, setSaleReportShow, Notify, saleReports, setSaleReports, shops, setShops, admin } = useContext(QtdContext);
  const thisSR = saleReports.find(pr => pr.id == id)
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "c2ontainerwsw12464qq" && setSaleReportShow(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const date = new Date(thisSR.createdAt);

  // Форматирование времени в формате HH:mm
  // const time = ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2);

  // Форматирование даты в формате YYYY-MM-DD
  const formattedDate = date.toISOString().slice(0, 10);
  const cmc = thisSR.reciepts.map(pr => JSON.parse(pr))
  const cmcsclad = thisSR.sclad.map(pr => JSON.parse(pr))
  let cmcsold = 0
  let cmcsale = 0
  let cmclost = 0
  let cmcsumm = 0
  for (let i = 0; i < cmc.length; i++) {
    cmcsold = cmcsold + Number(cmc[i].sold)
    cmcsale = cmcsale + Number(cmc[i].saleqty)
    cmclost = cmclost + Number(cmc[i].lostqty)
    cmcsumm = cmcsumm + Number(cmc[i].sold) * Number(cmc[i].price)
      - Number(cmc[i].sale10 ? cmc[i].sale10 : 0) * Number(cmc[i].price) * 0.1
      - Number(cmc[i].sale20 ? cmc[i].sale20 : 0) * Number(cmc[i].price) * 0.2
      - Number(cmc[i].sale30 ? cmc[i].sale30 : 0) * Number(cmc[i].price) * 0.3
    if (cmc[i].linked) {
      for (let z = 0; z < cmc[i].linked.length; z++) {
        cmcsold = cmcsold + Number(cmc[i].linked[z].sold)
        cmcsale = cmcsale + Number(cmc[i].linked[z].saleqty)
        cmclost = cmclost + Number(cmc[i].linked[z].lostqty)
        cmcsumm = cmcsumm + Number(cmc[i].linked[z].sold) * Number(cmc[i].linked[z].price)

      }

    }
  }
  const cm = cmc.filter(pr => pr.kategory == 'Кофемашина')
  const others = cmc.filter(pr => pr.kategory != 'Кофемашина')
  return (
    <>
      {saleReportShow && (
        <div onClick={closeModal} id="c2ontainerwsw12464qq" className={style.modal_container}>

          <div className={style.borders} style={{ width: '80%' }}>
            <h1>Отчет от {formattedDate}</h1>
            <div className={style.table2}>
              <h3>Продажи</h3>
              <table className={style.table}>
                <thead className={style.tableth}>
                  <tr>
                    <td className={style.tabletd}>Наименование</td>
                    <td className={style.tabletd}>Начало дня</td>
                    <td className={style.tabletd}>Конец дня</td>
                    <td className={style.tabletd}>Продано</td>
                    <td className={style.tabletd}>10%</td>
                    <td className={style.tabletd}>20%</td>
                    <td className={style.tabletd}>30%</td>

                    <td className={style.tabletd}>Акция</td>
                    <td className={style.tabletd}>Абонемент</td>
                    <td className={style.tabletd}>Списано</td>
                    <td className={style.tabletd}>Причина</td>
                    <td className={style.tabletd}>Сумма</td>
                  </tr>
                </thead>
                <tbody>
                  {cm.map(cms => {
                    let LinkedProd = []


                    LinkedProd = cms.linked

                    return (<>
                      <tr>
                        <td className={style.tabletd}>{cms.name}</td>
                        <td className={style.tabletd}>{cms.oldqty}</td>
                        <td className={style.tabletd}>{cms.newqty}</td>
                        <td className={style.tabletd}>{cms.sold}</td>
                        <td className={style.tabletd}>{cms.sale10}</td>
                        <td className={style.tabletd}>{cms.sale20}</td>
                        <td className={style.tabletd}>{cms.sale30}</td>
                        <td className={style.tabletd}>{cms.saleqty}</td>
                        <td className={style.tabletd}>{cms.salecomment}</td>
                        <td className={style.tabletd}>{cms.lostqty}</td>
                        <td className={style.tabletd}>{cms.lostcomment}</td>
                        <td className={style.tabletd}>{Number(cms.sold) * Number(cms.price)
                          - Number(cms.sale10 ? cms.sale10 : 0) * Number(cms.price) * 0.1
                          - Number(cms.sale20 ? cms.sale20 : 0) * Number(cms.price) * 0.2
                          - Number(cms.sale30 ? cms.sale30 : 0) * Number(cms.price) * 0.3
                        }RUB</td>
                      </tr>

                      {LinkedProd.map(pr => {


                        return (
                          <tr>
                            <td className={style.tabletd}>{pr.name}</td>
                            <td className={style.tabletd}>{pr.oldqty}</td>
                            <td className={style.tabletd}>{pr.newqty}</td>
                            <td className={style.tabletd}>{pr.sold}</td>
                            <td className={style.tabletd}></td>
                            <td className={style.tabletd}></td>
                            <td className={style.tabletd}></td>
                            <td className={style.tabletd}></td>
                            <td className={style.tabletd}></td>
                            <td className={style.tabletd}></td>
                            <td className={style.tabletd}></td>
                            <td className={style.tabletd}>{Number(pr.sold) * Number(pr.price)}RUB</td>
                          </tr>
                        )
                      })}
                    </>
                    )

                  })}


                  {others.map(cms => {
                    return (
                      <tr>
                        <td className={style.tabletd}>{cms.name}</td>
                        <td className={style.tabletd}> </td>
                        <td className={style.tabletd}> </td>
                        <td className={style.tabletd}><b>{cms.sold}</b></td>
                        <td className={style.tabletd}> </td>
                        <td className={style.tabletd}> </td>
                        <td className={style.tabletd}> </td>
                        <td className={style.tabletd}>{cms.saleqty}</td>
                        <td className={style.tabletd}>{cms.salecomment}</td>

                        <td className={style.tabletd}>{cms.lostqty}</td>
                        <td className={style.tabletd}>{cms.lostcomment}</td>
                        <td className={style.tabletd}>{Number(cms.sold) * Number(cms.price)}RUB</td>

                      </tr>
                    )
                  })}
                  <tr style={{ background: 'skyblue' }}>
                    <td className={style.tabletd}><b>Итого</b></td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}><b>{cmcsold}</b></td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> <b>{cmcsale}</b></td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}><b>{cmclost}</b> </td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> <b>{cmcsumm}RUB</b></td>

                  </tr>
                </tbody>
              </table>
            </div>

            <div className={style.table2}>
              <h3>Остатки</h3>
              <table className={style.table}>
                <thead className={style.tableth}>
                  <tr>
                    <td className={style.tabletd}>Наименование</td>
                    <td className={style.tabletd}>Начало дня</td>
                    {admin.role == 'AllRights' && (<>
                      <td className={style.tabletd}>Приход</td>
                      <td className={style.tabletd}>Рассход</td>
                    </>)}

                    <td className={style.tabletd}>Конец дня</td>
                    <td className={style.tabletd}>Ожидаемый расход</td>
                    <td className={style.tabletd}>Факт расход</td>
                    <td className={style.tabletd}>Разница</td>
                  </tr>
                </thead>
                <tbody>
                  {cmcsclad.map(cms => {
                    console.log(cms)
                    let color = 'black'
                    let text = null
                    if ((cms.awaited ? cms.awaited : 0) - (cms.scldqty - cms.andqty - cms.prihod + cms.rashod) != 0) {
                      color = 'red'
                    } else {
                      color = 'green'

                    }
                 
                    if ((cms.awaited ? cms.awaited : 0) - (cms.scldqty - cms.andqty - cms.prihod + cms.rashod) < 0) {
                      text = `(Перерасход)`
                    } else if ((cms.awaited ? cms.awaited : 0) - (cms.scldqty - cms.andqty - cms.prihod + cms.rashod) > 0) {
                      text = `(Пересорт)`

                    }

                    return (<>
                      <tr>
                        <td className={style.tabletd}>{cms.name}</td>
                        <td className={style.tabletd}>{cms.scldqty}{cms.mnt}</td>
                        {admin.role == 'AllRights' && (<>
                          <td className={style.tabletd}>{cms.prihod}{cms.mnt}</td>
                          <td className={style.tabletd}>{cms.rashod}{cms.mnt}</td>
                        </>)}

                        <td className={style.tabletd}>{cms.andqty}{cms.mnt}</td>
                        <td className={style.tabletd}>{cms.awaited ? cms.awaited.toFixed(2) : 0}{cms.mnt}</td>
                        <td className={style.tabletd}>{cms.scldqty - cms.andqty + cms.prihod - cms.rashod}{cms.mnt}</td>
                        <td className={style.tabletd}><b style={{ color: color }}>{(cms.awaited ? cms.awaited.toFixed(2) : 0) - Number((cms.scldqty - cms.andqty + cms.prihod - cms.rashod).toFixed(2))}</b>{cms.mnt}{text}</td>
                        {/* <td className={style.tabletd}>{cms.fact}</td> */}
                      </tr>


                    </>
                    )

                  })}


                  {/* <tr style={{ background: 'skyblue' }}>
                    <td className={style.tabletd}>Итого</td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}>{cmcsold}</td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> </td>
                    <td className={style.tabletd}> {cmcsumm}RUB</td>

                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  )
};

export default SaleReport;