import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
import { QtdContext } from "../..//context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../http/index.ts'
import { BACKURL } from '../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const EditShopModal = ({ id }) => {

  // console.log(id)

  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { Notify,setShops, shops, editShopModal, setEditShopModal, admins, setAdmins, admin } = useContext(QtdContext);
  const shop = shops.find(pr => pr.id === id)
  // console.log(shop)
  const [newValue, setNeValue] = useState({
    id: id,
    name: shop.name,
    tg_id: shop.tg_id,

  })
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "cederd" && setEditShopModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({

    tg_id: false,
    name: false,


  })

  const editPrice = async (e) => {
    try {
      e.preventDefault()
      // console.log(newValue)
      let t = {
        tg_id: false,
        name: false,
      }

      // if (!newValue.startdate) {
      //   t.startdate = `Выберите дану приема на работу!`

      // }
      if (!newValue.name) {
        t.name = `Выберите логин!`

      }


      setErr(t)
      if (
        !t.name &&

        newValue.name


      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/shop/edit`,
          { newValue: newValue, admin: admin.id }
        )
        setShops(shops.map(pr => pr.id == id ? newProd.data : pr))
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Точка успешно обновлена!",
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setEditShopModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }


  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {editShopModal && (
        <div onClick={closeModal} id="cederd" className={style.modal_container}>

          <div className={style.borders}>
            <div className={style.inputtest}>

              <label >Измените название:</label>

              <input type="text" id="name" name="name"
                value={newValue.name}
                onChange={(event) => {
                  setNeValue({ ...newValue, name: event.target.value });
                }} />
              {err.name && (
                <p style={{ color: "red" }}>{err.name}</p>
              )}
            </div>


            <div className={style.inputtest}>
              <label >Измените tg_id:</label>

              <input type="text" value={newValue.tg_id} id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, tg_id: event.target.value });
              }} />
              {err.tg_id && (
                <p style={{ color: "red" }}>{err.tg_id}</p>
              )}
            </div>



            <Button
              text="Изменить"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={editPrice}
            />



          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default EditShopModal;
