import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { FcCancel } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css

const AddRecieptModal = ({ }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { Notify,addReciepModal, setAddRecieptModal, setReciepts, reciepts, sclad, setSclad, admin } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({})
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "cont3ainer12464" && setAddRecieptModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({
    name: false,
    kategory: false,
    step: false,
    qty: false,
    mnt: false,
  })

  const [prodArr, setProdArr] = useState([])
  const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory)));

  const dlt = (prod_id) => {
    setProdArr(prodArr.filter(pr => pr.prod_id != prod_id))
  }
  const addProd = async (e) => {
    e.preventDefault()

    let t = {
      prod_id: false,
      admin_id: false,
      qty: false,
      shop_id: false,
      step: false,
      stepQty: false
    }
    if (!newValue.prod_id) {
      t.prod_id = `Выберите товар!`

    }
    if (!newValue.qty || Number(newValue.qty) < 0) {
      t.qty = `Введите положительное число!`

    }
    setErr(t)
    if (
      !t.prod_id &&
      !t.qty &&
      newValue.admin_id,
      newValue.prod_id,
      newValue.qty

    ) {
      const product = sclad.find(pr => pr.id == newValue.prod_id)

      setProdArr([...prodArr, {
        prod_id: newValue.prod_id,
        qty: newValue.qty,
        name: product.name,
        kategory: product.kategory,
        mnt: product.mnt,
      }])

      setNeValue({
        admin_id: admin.id,
        name: newValue.name,
        kategory: newValue.kategory,
        prod_id: '',
        qty: ''
      })
    }
  }

  const submit = async () => {
    confirmAlert({
      title: 'Создать товар',
      message: 'Вы уверены, что хотите создать новый товар?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editPrice()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  const editPrice = async () => {
    try {
      // e.preventDefault()

      let t = {
        name: false,
        reciept: false

      }



      if (!newValue.name) {
        t.name = `Введите наименование!`

      }

      if (!prodArr.length > 0) {
        t.reciept = `Введите количество!`

      }

      setErr(t)
      if (
        !t.name &&

        !t.reciept &&
        newValue.name

      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/reciepts/create`,
          { prodArr: prodArr, newValue: newValue, admin: admin.id }
        )
        setReciepts([...reciepts, newProd.data])
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Продукт успешно создан!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setAddRecieptModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }
  const setProd = (prod_id) => {
    const thisProd = sclad.find(item => item.id == prod_id)

    const result = prodArr.find(item => item.prod_id == thisProd.id)
    // console.log(thisProd, !result)
    if (!result) {
      setNeValue({ ...newValue, prod_id: prod_id, step: thisProd.step, stepQty: thisProd.qty })

    } else {
      setErr({ prod_id: 'Данный товар уже был выбран!' })
    }
    // if()

  }
  // const qeroFilter = sclad.filter(pr => ((Number(pr.scldqty) - Number(pr.scldbron) > 0) && !checkDuplicateIds(sclad, prodArr)))
  const sorted = sclad.sort((a, b) => a.name > b.name ? 1 : -1);
  const setQty = (qty) => {
    const prod = sclad.find(pr => pr.id == newValue.prod_id)

    // if (prod.scldqty - prod.scldbron - qty >= 0) {
      setNeValue({ ...newValue, qty: qty });

    // } else {
    //   setErr({ qty: `Всего на складе доступно ${prod.scldqty - prod.scldbron}${prod.mnt} ${prod.name}!` })
    // }

  }
  function checkDuplicateIds(array1, array2) {
    const ids1 = array1.map(obj => obj.id);
    const ids2 = array2.map(obj => obj.prod_id);

    for (let i = 0; i < ids1.length; i++) {
      if (ids2.includes(ids1[i])) {
        return true;
      }
    }

    return false;
  }
  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {addReciepModal && (
        <div onClick={closeModal} id="cont3ainer12464" className={style.modal_container}>

          <div className={style.borders}>

            <div className={style.inputtest}>

              <label >Введите наименование:</label>

              <input type="text" id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, name: event.target.value });
              }} />
              {err.name && (
                <p style={{ color: "red" }}>{err.name}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Выберите категорию:</label>

              <select name="pets" id="prod"
                onChange={(event) => {
                  setNeValue({ ...newValue, kategory: event.target.value });
                }}
              >
                <option value="">--Выберите категорию--</option>
                <option value="Кофемашина">Кофемашина</option>
                <option value="Чай">Чай</option>
                <option value="Напитки без кофе">Напитки без кофе</option>
                <option value="Сироп">Сироп</option>
                <option value="Прочее">Прочее</option>
               
              </select>
              {err.kategory && (
                <p style={{ color: "red" }}>{err.kategory}</p>
              )}
            </div>
            {prodArr.length > 0 && (
              <div className={style.inputtest}>
                <ol>
                  {prodArr.map(pr => (
                    <li>
                      <div className={style.list}>
                        <div className={style.itemlist}>
                          {pr.name}  x  {pr.qty}{pr.mnt}
                        </div>
                        <div className={style.actionlist}>
                          <FcCancel onClick={() => dlt(pr.prod_id)} />

                        </div>
                      </div>
                    </li>
                  ))}
                </ol>

              </div>
            )}
            <div className={style.inputtest}>

              <label >Выберите товар:</label>

              <select name="pets" id="prod"
                value={newValue.prod_id}
                onChange={(event) => {
                  setProd(event.target.value)

                }}
              >
                <option value="">--Выберите ингридиент--</option>

                {uniqueSerials.map(serials => {

                  const table = sorted.filter(prod => (prod.kategory === serials))

                  return (<>
                    <optgroup label={`${serials}`}>
                      {table.map(prod => (
                        <option value={`${prod.id}`}>{prod.name}, {prod.mnt}</option>

                      ))}
                    </optgroup></>
                  )
                }
                )}


              </select>
              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Введите количество:</label>


              <input type="number" id="pass" name="pass"

                value={newValue.qty}
                onChange={(event) => {
                  setQty(event.target.value)

                }} />

              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </div>
            <Button
              text="Добавить ингридиент"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={addProd}
            />
            <div className={style.inputtest}>

              <label >Введите цену:</label>


              <input type="number" id="pass" name="pass"
                value={newValue.price}
                onChange={(event) => {
                  setNeValue({ ...newValue, price: event.target.value });

                }} />

              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </div>





            <Button
              text="Создать Продукт"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={submit}
            />


          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default AddRecieptModal;
