import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { FcEngineering, FcCancel } from "react-icons/fc";
import "react-datepicker/dist/react-datepicker.css";
// import EditScladOrderListModal from "../Sclad/EditScladOrderListModal.js";
// import AddRecieptListModal from "./AddRecieptListModal.js";
// import EditRecieptListModal from "./EditRecieptListModal.js";
import { CiLink } from "react-icons/ci";

const SRListModal = ({id }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [loading, seloading] = useState(false)

  const { srListModal, setSRListModal,Notify, saleReportForms,reciepts, setEditRecieptListModal, addRecieptOrderListModal, setAddRecieptOrderListModal, recieptListModal, setRecieptListModal, addScladOrderListItemModal, setAddScladOrderListItemModal, editScladOrderListItemModal, setReciepts, scladOrderListModal, setScladOrderListModal, setShops, admin, setSclad, setScladOrders, scladOrders } = useContext(QtdContext);
  const thisForm = saleReportForms.find(ff=>ff.id==id)
  console.log(id, saleReportForms)
  const recieptsss =thisForm.reciepts.map(pr=>JSON.parse(pr))
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "srListModal3" && setSRListModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [editItem, setEditItem] = useState({})
  const OpnaEditModal = (order_id, prod_id) => {
    setEditItem({ order_id, prod_id })

    setEditRecieptListModal(true)
  }
  // const OpnaAddModal = ( e) =>{
  //   // setEditItem(order_id )

  //   setEditScladOrderListItemModal(true)
  // }
  const deletIt = async (order_id, prod_id) => {
    try {
      seloading(true)
      const newProd = await $api.post(`${BACKURL}/api/reciepts/deleteitem`,
        { order_id, prod_id, admin: admin.id })

      setReciepts(
        newProd.data
      )
      seloading(false)
      Notify.addNotification({
        title: "Готово!",
        message: "Ингридиент удален из списка!",
        type: "success",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
      setSRListModal(false)

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
      // console.log(e)
    }
  }
  return (
    <>
      {srListModal && (
        <div onClick={closeModal2} id="srListModal3" className={style.modal_container}>

          <div className={style.borders } style={{height:'auto'}}>
           
          <div className={style.shopsdiv}>
              <h5>Продукты c счетчиком в кофемащине</h5>
              <br></br>
              <div className={style.shopsdivcoffees} style={{flexDirection:'column', height:'auto'}}>
                {recieptsss.map((shop, index) => {
                  if (shop.kategory == 'Кофемашина') {
                    return (<>
                      <div className={style.coffeess}>
                        <div className={style.form_radio_btn}


                        >

                          <label htmlFor={`radio_${shop.id}`}
                            // onClick={() => handleRadioChangereciepts(shop.id)}
                            style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                          </label>
                          <input type="radio" name="radio_group"
                            checked={shop.checked}
                            style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                          // className={style.form_radio_btn}
                          />
                        </div>
                        <div>
                          {shop.checked && (
                            <CiLink style={{ fontSize: '50px' }}
                            //  onClick={() => Linked(shop.id)}
                             >

                            </CiLink>
                          )}
                        </div>
                        <div>
                          {shop.linked && (<>
                            {shop.linked.length > 0 && (
                              <div>
                                {shop.linked.map(item => {
                                  const linkedcoff = reciepts.find(pr => pr.id == item)
                                  if (linkedcoff) {
                                    return (
                                      <div className={style.form_radio_btn}


                                      >

                                        <label htmlFor={`radio_${shop.id}`}
                                          style={{ background: '#BCEDF6' }}>{linkedcoff.name}
                                        </label>
                                        <input type="radio" name="radio_group"
                                          // checked={shop.checked}
                                          style={{ background: '#BCEDF6' }}
                                        // className={style.form_radio_btn}
                                        />
                                      </div>
                                    )
                                  }
                                })}
                              </div>
                            )}
                          </>

                          )}

                        </div>
                      </div>
                    </>




                    )
                  }


                })}
              </div>
            </div>

          </div>

        </div>
      )}
      {/* {editRecieptListModal && (
        <EditRecieptListModal
          order_id={editItem.order_id}
          prod_id={editItem.prod_id}
        ></EditRecieptListModal>
      )} */}
      {/* {addRecieptOrderListModal && (
        <AddRecieptListModal
          order_id={order_id}
        ></AddRecieptListModal>
      )} */}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default SRListModal;
