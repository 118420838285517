import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css

import "react-datepicker/dist/react-datepicker.css";
const AddEmployeesModal = ({ }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { Notify,addEmployeesModal, setAddEmployeesModal, admins, setAdmins, admin } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({})
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12464" && setAddEmployeesModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({
    login: false,
    pass: false,
    startdate:false,
    email: false,
    phone: false,
    role: false,
    fullname: false,
    birthsday: false,

  })

  const editPrice = async () => {
    try {
      // e.preventDefault()

      let t = {
        startdate:false,
        login: false,
        pass: false,
        email: false,
        phone: false,
        role: false,
        fullname: false,
        birthsday: false,
      }

      if (!newValue.startdate) {
        t.startdate = `Выберите дану приема на работу!`

      }
      if (!newValue.login) {
        t.login = `Выберите логин!`

      }
      if (!newValue.pass) {
        t.pass = `Введите пароль!`

      }

   
      if (!newValue.phone) {
        t.phone = `Выберите телефон!`

      }
      if (!newValue.role) {
        t.role = `Выберите роль!`

      }
      if (!newValue.fullname) {
        t.fullname = `Выберите ФИО!`

      }
      if (!newValue.birthsday) {
        t.birthsday = `Выберите дату рождения!`

      }

      setErr(t)
      if (
        !t.birthsday &&
        !t.fullname &&
        !t.role &&
        !t.phone &&
        !t.pass &&
        !t.login &&
        newValue.birthsday,
        newValue.fullname,
        newValue.role,
        newValue.phone,
        newValue.pass,
        newValue.login

      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/admins/create`,
          { newValue: newValue, admin: admin.id }
        )
        setAdmins([...admins, newProd.data])
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Сотрудник успешно создан!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setAddEmployeesModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      console.log(e)
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }
  const submit = async () => {
    confirmAlert({
      title: 'Создать сотрудника',
      message: 'Вы уверены, что хотите создать сотрудника?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editPrice()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {addEmployeesModal && (
        <div onClick={closeModal} id="container12464" className={style.modal_container}>

<div className={style.borders}>
              <div className={style.inputtest}>

                <label >Введите ФИО:</label>

                <input type="text" id="pass" name="pass" onChange={(event) => {
                  setNeValue({ ...newValue, fullname: event.target.value });
                }} />
                {err.fullname && (
                  <p style={{ color: "red" }}>{err.fullname}</p>
                )}
              </div>
              <div className={style.inputtest}>

                <label >Выберите роль:</label>

                <select name="pets" id="prod"
                  onChange={(event) => {
                    setNeValue({ ...newValue, role: event.target.value });
                  }}
                >
                  <option value="">--Выберите категорию--</option>
                  <option value="Администратор">Администратор</option>
                  <option value="Доставщик">Доставщик</option>
                  <option value="Кладовщик">Кладовщик</option>
                  <option value="Бариста">Бариста</option>

                </select>
                {err.role && (
                  <p style={{ color: "red" }}>{err.role}</p>
                )}
              </div>

              <div className={style.inputtest}>
                <label >Введите логин:</label>

                <input type="text" id="pass" name="pass" onChange={(event) => {
                  setNeValue({ ...newValue, login: event.target.value });
                }} />
                {err.login && (
                  <p style={{ color: "red" }}>{err.login}</p>
                )}
              </div>
              <div className={style.inputtest}>
                <label >Введите пароль:</label>

                <input type="text" id="pass" name="pass" onChange={(event) => {
                  setNeValue({ ...newValue, pass: event.target.value });
                }} />
                {err.pass && (
                  <p style={{ color: "red" }}>{err.pass}</p>
                )}
              </div>
              <div className={style.inputtest}>
                <label >Введите телефон:</label>

                <input type="text" id="phone" name="phone" onChange={(event) => {
                  setNeValue({ ...newValue, phone: event.target.value });
                }} />
                {err.phone && (
                  <p style={{ color: "red" }}>{err.phone}</p>
                )}
              </div>
              <div className={style.inputtest}>
                <label >Введите email:</label>

                <input type="text" id="email" name="email" onChange={(event) => {
                  setNeValue({ ...newValue, email: event.target.value.toLowerCase() });
                }} />
                {err.email && (
                  <p style={{ color: "red" }}>{err.email}</p>
                )}
              </div>
              <div className={style.inputtest}>

                <label >Выберите дату рождения:</label>

                <DatePicker selected={newValue.birthsday} onChange={(date) => { setNeValue({ ...newValue, birthsday: date }) }} />
                {err.birthsday && (
                  <p style={{ color: "red" }}>{err.birthsday}</p>
                )}
              </div>
              <div className={style.inputtest}>

                <label >Выберите дату начала работы:</label>

                <DatePicker selected={newValue.startdate} onChange={(date) => { setNeValue({ ...newValue, startdate: date }) }} />
                {err.startdate && (
                  <p style={{ color: "red" }}>{err.startdate}</p>
                )}
              </div>

              <Button
                text="Создать"
                background="var(--primary-color)"
                color="white"
                // width="100%"
                onClick={submit}
              />

            

          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default AddEmployeesModal;
