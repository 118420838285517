import React from "react";
import style from "./Home.module.css";
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FcCancel, FcEngineering, FcInfo } from "react-icons/fc";
import $api from '../../../http/index.ts'
import './react-confirm-alert.css'; // Import css

import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu";
// import AddProductModal from "../../../components/Modals/AddProductModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import AddEmployeesModal from "../../../components/Modals/Emloyees/AddEmployeesModal.js";
import EditEmployeesModal from "../../../components/Modals/Emloyees/EditEmployeesModal.js";
import AddRecieptModal from "../../../components/Modals/Reciepts/AddRecieptModal.js";
import RecieptListModal from "../../../components/Modals/Reciepts/RecieptListModal.js";
import EditRecieptModal from "../../../components/Modals/Reciepts/EditRecieptModal.js";

const Products = () => {
  const { Notify,editRecieptModal, setEditRecieptModal,setReciepts, recieptListModal, setRecieptListModal, reciepts, addReciepModal, setAddRecieptModal, editEmployeesModal, setEditEmployeesModal, admin, admins, setAdmins, addEmployeesModal, setAddEmployeesModal, setDashboarPage, setAdmin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [id, setId] = useState('')
  const [itemlist, setItemlist] = useState([])
  const [orderId, setOrderId] = useState({})
  if (!(admin.role == 'AllRights' || admin.role == 'Администратор')) {
    setDashboarPage('Home')
    // setAdmin({ auth: false});
    return <Navigate to="/" replace />

  } else {
    const showList = (listik, id) => {
      setOrderId(id)
      setItemlist(listik)
      setRecieptListModal(true)
    }
    // console.log(admin.role != 'GOD' && admin.role != 'Администратор')
    // useEffect(()=>{})

    function customSort(a, b) {
      const categoriesOrder = { 'Кофемашина': 1, 'Напитки без кофе': 2, 'Чай': 3, 'Сироп': 4, 'Прочее': 5 };
      if (a.kategory !== b.kategory) {
        return categoriesOrder[a.kategory] - categoriesOrder[b.kategory];
      } else {
        return a.name.localeCompare(b.name);
      }
    }
    const users = admins.filter(pr => pr.role != 'AllRights')
    const sortedd = reciepts.filter(pr=>pr.deleted==false);
    const sorted = sortedd.sort(customSort);
    const addProduct = () => {
      setAddRecieptModal(true)
    }

    // useEffect(())

    const OpnaEditModal = (id) => {
      setId(id)
      setEditRecieptModal(id)
    }
    const deleteIt = async (id) => {
      try {
        seloading(true)
        const deleteProd = await $api.get(`${BACKURL}/api/reciepts/delete/${id}`)
        setReciepts(reciepts.filter(item => item.id != id))
        seloading(false)

        Notify.addNotification({
          title: "Готово!",
          message: "Продукт успешно удален из системы!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } catch (e) {
        console.log(e)
        seloading(false)

        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    }

    const submit = async (func, id) => {
      confirmAlert({
        title: 'Удалить продукт',
        message: 'Вы уверены, что хотите удалить продукт из системы?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
    return (<>

      <div className={style.sclad_container}>
        <Button text='Создать Продукт' background='orange' onClick={addProduct} />

        <div className={style.table2}>
          <table className={style.table}>
            <thead className={style.tableth}>

              <tr>
                <td className={style.tabletd}>№</td>
                <td className={style.tabletd}>Наименование</td>
                <td className={style.tabletd}>Категория</td>
                <td className={style.tabletd}>Тех.Карта</td>
                <td className={style.tabletd}>Цена</td>
                <td className={style.tabletd}>Действия</td>

              </tr>
            </thead>
            <tbody>
              {sorted.map(employee => {
                // Сбор итоговой строки
                return (<tr>
                  <td className={style.tabletd}>{employee.id}</td>
                  <td className={style.tabletd}>{employee.name}</td>
                  <td className={style.tabletd}>{employee.kategory}</td>
                  <td className={style.tabletd}>
                    <div className={style.cell}>
                      <FcInfo
                        onClick={() => showList(employee.reciept, employee.id)}
                      />
                    </div>
                  </td>
                  <td className={style.tabletd}>{employee.price}</td>

                  <td className={style.tabletd} style={{
                    'display': 'flex',
                    'justifyContent': 'space-around'
                  }}>
                    <div className={style.cell}>
                      <FcEngineering
                        onClick={() =>
                          OpnaEditModal(employee.id)
                        }
                      />
                    </div>
                    <div className={style.cell}>

                      <FcCancel onClick={() => submit(deleteIt, employee.id)} />
                    </div>
                  </td>
                </tr>)
              }
              )}



            </tbody>
          </table>
        </div>

      </div>

      {editRecieptModal && (
        <EditRecieptModal
          id={id}

        />

      )}
      {recieptListModal && (
        <RecieptListModal
          scladOrderdata={itemlist}
          order_id={orderId}
        />

      )}
      {addReciepModal && (
        <AddRecieptModal

        />

      )}

      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}


    </>
    );
  }


};

export default Products;
