import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const ScladHistoryListModal = ({ scladHistorydata, type }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { scladHistoryListModal, setScladHistoryListModal, shops, setShops, admin } = useContext(QtdContext);
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "gjh2hkk" && setScladHistoryListModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };




  return (
    <>
      {scladHistoryListModal && (
        <div onClick={closeModal2} id="gjh2hkk" className={style.modal_container}>

          <div className={style.borders}>

            {/* <div className={style.inputtest} style={'margin'}> */}

            <div className={style.table2}>
              <table className={style.table}>
                <thead className={style.tableth}>

                  <tr>
                  <td className={style.tabletd}>№</td>

                    <td className={style.tabletd}>Наименование</td>
                    {type == 'Инвентаризация' && (
                      <td className={style.tabletd}>До Инвентаризации</td>

                    )}
                    <td className={style.tabletd}>Количество</td>

                    <td className={style.tabletd}>Ед</td>

                  </tr>
                </thead>
                <tbody>
                  {scladHistorydata.map((employee, index) => {
                    const item = JSON.parse(employee)
                    // console.log(scladHistorydata, employee, item)
                    return (<tr>
                                            <td className={style.tabletd}>{index+1}</td>

                      <td className={style.tabletd}>{item.name}</td>
                      {type == 'Инвентаризация' && (
                        <td className={style.tabletd}>{item.oldValue}</td>

                      )}
                      <td className={style.tabletd}>{item.qty}</td>
                      <td className={style.tabletd}>{item.mnt}</td>

                    </tr>)
                  }
                  )}



                </tbody>
              </table>
            </div>





            {/* </div> */}

          </div>


        </div>
      )}
      {/* {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )} */}
    </>
  );
};

export default ScladHistoryListModal;
