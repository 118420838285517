import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
import { QtdContext } from "../..//context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../http/index.ts'
import { BACKURL } from '../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const AddShopModal = ({ }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { addShopModal, setAddShopModal,       shops, setShops, admin } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({})
  const [loading, seloading] = useState(false)
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "gjhhkk" && setAddShopModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({
    name: false,
    opandate: false,
  
  })

  const editPrice = async (e) => {
    try {

      e.preventDefault()
      let t = {
        name: false,
        opandate: false,
       
      }


      if (!newValue.opandate) {
        t.opandate = `Выберите дату открытия!`

      }
      if (!newValue.name) {
        t.name = `Введите наименование!`

      }

      

      setErr(t)
      if (
        !t.name &&
        !t.opandate &&
       
        newValue.name,
        newValue.opandate
      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/shop/create`,
          { newValue: newValue, admin: admin.id }
        )
        setShops([...shops, newProd.data])
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)

        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setAddShopModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      seloading(false)

    }


  }


  return (
    <>
      {addShopModal && (
        <div onClick={closeModal2} id="gjhhkk" className={style.modal_container}>

<div className={style.borders}>

              <div className={style.inputtest}>

                <label >Введите наименование:</label>

                <input type="text" id="pass" name="pass" onChange={(event) => {
                  setNeValue({ ...newValue, name: event.target.value });
                }} />
                {err.name && (
                  <p style={{ color: "red" }}>{err.name}</p>
                )}
              </div>
              <div className={style.inputtest}>

                <label >Выберите дату открытия:</label>

                <DatePicker selected={newValue.opandate} onChange={(date) => { setNeValue({ ...newValue, opandate: date }) }} />
                {err.opandate && (
                  <p style={{ color: "red" }}>{err.kategory}</p>
                )}
              </div>




     <Button
              text="Добавить Продукт"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={editPrice}
            />
       
          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default AddShopModal;
