import style from "./PAgination.css";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
const PaginationComponent = ({ data, handlePageCountChange, currentPage, itemsPerPage, onPageChange }) => {
    const pageCount = 100;


    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
    }  // Function to handle click on page number
    const handlePageClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    const handlePageCountClick = (pageNumber) => {
        handlePageCountChange(pageNumber);
        onPageChange(1)
    };
    const ieconStyle = {
        borderRadius: '10px',
        fontWeight: 'bold',
        color: 'white',

        margin: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',

        textAlign: 'center',
        height: '40px',
        width: '50px',
        border: 'solid white 3px'
    }
    // Render the pagination controls
    return (
        <div
        // className={style.paginationContainer}
        >
            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>
                <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>

                    <FaMinus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                        if (currentPage - 1 >= 1) {
                            handlePageClick(currentPage - 1)
                        }

                    }} />
                    <div
                        // value={cof.newqty-cof.oldqty}
                        style={ieconStyle}
                    >
                        {currentPage}
                    </div>
                    <FaPlus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                        if (currentPage + 1 >= 1) {
                            handlePageClick(currentPage + 1)
                        }
                    }} />
                </div>
                <select onChange={(e) => handlePageCountClick(e.target.value)}>
                    {/* <option value={5}>5</option>
                    <option value={10}>10</option> */}
                    <option value={15}>15</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>


            {/* {pageNumbers.map(number => (
                <button
                    key={number}
                    onClick={() => handlePageClick(number - 1)}
                    className={style.pageItem}
                >
                    {number}
                </button> */}
            {/* ))} */}
        </div>
    );
};
export default PaginationComponent;