import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// import shops from "../../../../citycoffee_back/src/models/shops.js";
const ScladSelectedOrdersModal = ({ status }) => {
  console.log(status)
  // console.log(id)

  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [loading, setLoading] = useState(false)
  const { Notify,scladSelectedOrdersModal, shops, setScladSelectedOrdersModal, editEmployeesModal, setEditEmployeesModal, addEmployeesModal, setAddEmployeesModal, admins, setAdmins, admin, scladOrders } = useContext(QtdContext);
  const orders = scladOrders.filter(pr => pr.status === 'Собирается!')
  const [data, setData] = useState(orders.map(pr => { return { ckd: false, ...pr } }))
  // console.log(data)
  // console.log(orders)
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "container1246422q" && setScladSelectedOrdersModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  // const editPrice = async (e) => {
  //   try {
  //     e.preventDefault()
  //     console.log(newValue)
  //     let t = {
  //       startdate: false,
  //       login: false,
  //       pass: false,
  //       email: false,
  //       phone: false,
  //       role: false,
  //       fullname: false,
  //       birthsday: false,
  //     }

  //     // if (!newValue.startdate) {
  //     //   t.startdate = `Выберите дану приема на работу!`

  //     // }
  //     if (!newValue.login) {
  //       t.login = `Выберите логин!`

  //     }
  //     if (!newValue.pass) {
  //       t.pass = `Введите пароль!`

  //     }

  //     // if (!newValue.email) {
  //     //   t.email = `Введите email!`

  //     // }
  //     // if (!newValue.phone) {
  //     //   t.phone = `Выберите телефон!`

  //     // }
  //     if (!newValue.role) {
  //       t.role = `Выберите роль!`

  //     }
  //     if (!newValue.fullname) {
  //       t.fullname = `Выберите ФИО!`

  //     }
  //     // if (!newValue.birthsday) {
  //     //   t.birthsday = `Выберите дату рождения!`

  //     // }

  //     setErr(t)
  //     if (
  //       !t.birthsday &&
  //       !t.fullname &&
  //       !t.role &&
  //       !t.phone &&
  //       !t.email &&
  //       !t.pass &&
  //       !t.login &&
  //       newValue.birthsday,
  //       newValue.fullname,
  //       newValue.role,
  //       newValue.phone,
  //       newValue.email,
  //       newValue.pass,
  //       newValue.login

  //     ) {
  //       seloading(true)
  //       // console.log(newValue)
  //       const newProd = await $api.post(`${BACKURL}/api/admins/edit`,
  //         { newValue: newValue, admin: admin.id }
  //       )
  //       setAdmins(admins.map(pr => pr.id == id ? newProd.data : pr))
  //       // setServOrders(newPosts)
  //       // setSclad(newPosts)

  //       // //Проверь правильность данных тут
  //       // setServerProd(newPosts)
  //       // console.log(newProd.data)
  //       // setModalInfo(false)
  //       seloading(false)

  //       // localStorage.setItem("petNew", JSON.stringify(newPosts));
  //       setEditEmployeesModal(false)
  //       // alert('Добавлено!')
  //     }

  //   } catch (e) {
  //     seloading(false)

  //   }


  // }

  // const [checkAll, setCheckAll] = useState(false)
  const handleChangeAll = async (e) => {
    // e.preventDefault()
    setLoading(true)

    const { checked } = e.target
      // console.log('true-false - ', checked)
      setData(data.map(pr => {
        return {
          ...pr, ckd: checked
        }
      }))
      // setCheckAll(false)

      setLoading(false)


  }
  //Перенести заказы
  const handleChange = async (e, id) => {
    const { checked } = e.target
    // setLoading(true)

    setData(data.map(pr => {
      if (pr.id === id) {
        return {
          ...pr, ckd: checked
        }
      } else {
        return pr
      }

    }))
    // setLoading(false)

  }

  const Down = async () => {
    // console.log('Достаем накладную!')
    // console.log(data)
    try {
      setLoading(true)
      // const order = scladOrders.find(pr=>pr.id==id)
      // Отправляем запрос на сервер для скачивания Excel-файла
      const response = await $api.post(`${BACKURL}/api/xlsx/downloadselectedorders`, data, {
        responseType: 'blob', // Указываем, что ожидаем blob в ответе
      })

      // Создаем ссылку для скачивания и нажимаем на нее
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `Общая накладная.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false)
      Notify.addNotification({
        title: "Готово!",
        message: "Вы можете найти накладную в загрузках!",
        type: "info",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    } catch (error) {
      setLoading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
      console.error('Error:', error);
    }
  }
  // if(status=='Передан на склад!'){
  //   console.log('Собираем!')
  //   console.log(data)

  // }

  // if(status=='Собирается!'){












  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {scladSelectedOrdersModal && (
        <div onClick={closeModal2} id="container1246422q" className={style.modal_container}>

          <div className={style.borders}>
            <div className={style.inputtest} style={{ flexDirection: "row" }}>
           
              <ul>
                <li><div>
                     <input type="checkbox"
                onChange={e => handleChangeAll(e)}
                // value={checkAll}
                // defaultChecked={false} 
                /> Выбрать все
                  </div></li>
                {data.map(pr => {
                  const shop = shops.find(pr2 => pr2.id == Number(pr.shop_id))
                  // console.log(pr)
                  return (
                    <li>
                      <div>
                        <input type="checkbox"
                          onChange={e => handleChange(e, pr.id)}
                          checked={pr.ckd}
                          defaultChecked={pr.ckd} 
                          />
                        №{pr.id} - {shop.name}
                        {/* {this.state.checked.toString()} */}
                      </div>

                    </li>
                  )
                })}
              </ul>
            </div>

            {data.length > 0 && (
              <Button
                text="Общая накладная!"
                background="var(--primary-color)"
                color="white"
                // width="100%"
                onClick={Down}
              />
            )}




          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default ScladSelectedOrdersModal;
