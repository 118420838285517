import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { FcEngineering, FcCancel } from "react-icons/fc";
import "react-datepicker/dist/react-datepicker.css";
import EditScladOrderListModal from "../Sclad/EditScladOrderListModal.js";
import AddRecieptListModal from "./AddRecieptListModal.js";
import EditRecieptListModal from "./EditRecieptListModal.js";
import './react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert'; // Import

const RecieptListModal = ({ scladOrderdata, status, order_id }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [loading, seloading] = useState(false)

  const { Notify,editRecieptListModal, setEditRecieptListModal,addRecieptOrderListModal, setAddRecieptOrderListModal,recieptListModal, setRecieptListModal,addScladOrderListItemModal, setAddScladOrderListItemModal, editScladOrderListItemModal, setReciepts, scladOrderListModal, setScladOrderListModal, setShops, admin, setSclad, setScladOrders, scladOrders } = useContext(QtdContext);
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "gjh2hkkq" && setRecieptListModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [editItem, setEditItem] = useState({})
  const OpnaEditModal = (order_id, prod_id) => {
    setEditItem({ order_id, prod_id })

    setEditRecieptListModal(true)
  }
  // const OpnaAddModal = ( e) =>{
  //   // setEditItem(order_id )

  //   setEditScladOrderListItemModal(true)
  // }
  const submit = async (order_id, prod_id) => {
    confirmAlert({
      title: 'Создание кофемашины',
      message: 'Вы уверены, что хотите создать удалить ингридиент?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await deletIt(order_id, prod_id)
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  const deletIt = async (order_id, prod_id) => {
    try {
      seloading(true)
      const newProd = await $api.post(`${BACKURL}/api/reciepts/deleteitem`,
        { order_id, prod_id, admin: admin.id })

        setReciepts(
        newProd.data
      )
      seloading(false)

      setRecieptListModal(false)
      Notify.addNotification({
        title: "Готово!",
        message: "Продукт успешно удален!",
        type: "info",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
      // console.log(e)
    }
  }
  return (
    <>
      {recieptListModal && (
        <div onClick={closeModal2} id="gjh2hkkq" className={style.modal_container}>

          <div className={style.borders}>
            {/* {status == "Передан на склад!" && ( */}
              <Button
                text="Добавить товар"
                background="var(--primary-color)"
                color="white"
                // width="100%"
                onClick={() => setAddRecieptOrderListModal(true)
                }
              />
            {/* )} */}


            {/* <div className={style.inputtest} style={'margin'}> */}

            <div className={style.table2}>
              <table className={style.table}>
                <thead className={style.tableth}>

                  <tr>
                    <td className={style.tabletd}>Наименование</td>
                    <td className={style.tabletd}>Количество</td>
                    <td className={style.tabletd}>Ед</td>
                    {/* {status == "Передан на склад!" && ( */}
                      <td className={style.tabletd}>Изменить</td>

                    {/* )} */}

                  </tr>
                </thead>
                <tbody>
                  {scladOrderdata.map(employee => {
                    const item = JSON.parse(employee)
                    // console.log(scladOrderdata, employee, item)
                    return (<tr>
                      <td className={style.tabletd}>{item.name}</td>
                      <td className={style.tabletd}>{item.qty}</td>
                      <td className={style.tabletd}>{item.mnt}</td>
                      <td> 
                        <div className={style.cells}>
                          <div className={style.cell}>
                            <FcEngineering
                              onClick={() =>
                                OpnaEditModal(order_id, item.prod_id)
                              }
                            />
                          </div>
                          <div className={style.cell}>
                            <FcCancel
                              onClick={() =>
                                submit(order_id, item.prod_id)
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>)
                  }
                  )}



                </tbody>
              </table>
            </div>





            {/* </div> */}


          </div>

        </div>
      )}
      {editRecieptListModal && (
        <EditRecieptListModal
          order_id={editItem.order_id}
          prod_id={editItem.prod_id}
        ></EditRecieptListModal>
      )}
      {addRecieptOrderListModal && (
        <AddRecieptListModal
          order_id={order_id}
        ></AddRecieptListModal>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default RecieptListModal;
