import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const SaleReportShort = ({ id }) => {
  const { saleReportShortShow, setSaleReportShortShow, reciepts, Notify, saleReports, setSaleReports, shops, setShops, admin } = useContext(QtdContext);
  const thisSR = saleReports.find(pr => pr.id == id)
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "c2ontaidnerwsw12464qq" && setSaleReportShortShow(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const date = new Date(thisSR.createdAt);
  const shop = shops.find(pr => pr.id == thisSR.shop_id)
  // Форматирование времени в формате HH:mm
  // const time = ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2);

  // Форматирование даты в формате YYYY-MM-DD
  const formattedDate = date.toISOString().slice(0, 10);
  const cmc = thisSR.reciepts.map(pr => JSON.parse(pr))
  const cmcsclad = thisSR.sclad.map(pr => JSON.parse(pr))
  let cmcsold = 0
  let cmcsale = 0
  let cmclost = 0
  let cmcsumm = 0
  for (let i = 0; i < cmc.length; i++) {
    cmcsold = cmcsold + Number(cmc[i].sold)
    cmcsale = cmcsale + Number(cmc[i].saleqty)
    cmclost = cmclost + Number(cmc[i].lostqty)
    cmcsumm = cmcsumm + Number(cmc[i].sold) * Number(cmc[i].price)
      - Number(cmc[i].sale10 ? cmc[i].sale10 : 0) * Number(cmc[i].price) * 0.1
      - Number(cmc[i].sale20 ? cmc[i].sale20 : 0) * Number(cmc[i].price) * 0.2
      - Number(cmc[i].sale30 ? cmc[i].sale30 : 0) * Number(cmc[i].price) * 0.3
    if (cmc[i].linked) {
      for (let z = 0; z < cmc[i].linked.length; z++) {
        cmcsold = cmcsold + Number(cmc[i].linked[z].sold)
        cmcsale = cmcsale + Number(cmc[i].linked[z].saleqty)
        cmclost = cmclost + Number(cmc[i].linked[z].lostqty)
        cmcsumm = cmcsumm + Number(cmc[i].linked[z].sold) * Number(cmc[i].linked[z].price)

      }

    }
  }
  const coffee = cmc.filter(pr => pr.kategory == 'Кофемашина')
  const drincs = cmc.filter(pr => pr.kategory == 'Напитки без кофе')
  const nocoffee = cmc.filter(pr => pr.kategory != 'Кофемашина' && pr.kategory != 'Напитки без кофе')

  return (
    <>
      {saleReportShortShow && (
        <div onClick={closeModal} id="c2ontaidnerwsw12464qq" className={style.modal_container2}>

          <div className={style.borders2} style={{ width: '80%' }}>
            <h5 style={{ color: 'white' }}>{formattedDate}</h5>
            <h5>{shop.name}</h5>
            <h6>{thisSR.status}</h6>
            <h6 style={{ color: 'white' }}>{cmcsumm}РУБ</h6>
            <Tabs style={{width:"100%"}} >
              <TabList>
                <Tab style={{ background: 'none', border: 'none', color: "white" }} key={"Продажи"}><h5>Продажи</h5></Tab>

                <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Счетчики'}><h5>Расход</h5></Tab>
                <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Остатки'}><h5>Приход</h5></Tab>
              </TabList>
              <TabPanel>
                <Tabs forceRenderTabPanel>
                  <TabList>
                    <Tab style={{ background: 'none', border: 'none', color: "white", opacity: "0.65" }} key={'Кофе'}><h6>Кофе</h6></Tab>
                    <Tab style={{ background: 'none', border: 'none', color: "white", opacity: "0.65" }} key={"Напитки"}><h6>Напитки</h6> </Tab>
                    <Tab style={{ background: 'none', border: 'none', color: "white", opacity: "0.65" }} key={'Прочее'}><h6>Прочее</h6> </Tab>
                  </TabList>

                  <TabPanel>
                    <div style={{ width: "100%" }}>
                      {coffee.map((cof, index) => <>
                        <div className={style.itemdd} >
                          {/* <div style={{width:"100%"}}> */}
                          <div style={{ width: "50%" }}>{cof.name}</div>
                          <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                            <div
                              // value={cof.newqty-cof.oldqty}
                              style={{ width: "30%", textAlign: "center" }}

                              value={cof.sold} placeholder="Продано">
                              {cof.sold}
                            </div>

                          </div>
                          <div style={{ width: "20%" }}>{cof.sold * cof.price - cof.sale10 * 0.1 * cof.price - cof.sale20 * 0.2 * cof.price - cof.sale30 * 0.3 * cof.price}РУБ</div>

                        </div>
                        {/* {coffee.linked && (<> */}
                        {cof.linked.map(pr => {
                          const item = reciepts.find(prq => pr.id == prq.id)
                          // console.log(item.name, cof.linked, pr, item)
                          return (
                            <div className={style.itemdd} >
                              {/* <div style={{width:"100%"}}> */}
                              <div style={{ width: "50%" }}>{item.name}</div>
                              <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>


                                <div
                                  style={{ width: "30%", textAlign: "center" }}
                                  onChange={(e) => {

                                  }}

                                  value={pr.sold} placeholder="Продано">
                                  {pr.sold}
                                </div>

                              </div>
                              <div style={{ width: "20%" }}>{pr.sold * item.price}РУБ</div>

                            </div>
                          )
                        })}
                        {/* </>)} */}
                      </>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div style={{ width: "100%" }}>
                      {drincs.map((cof, index) => <>
                        <div className={style.itemdd} >
                          {/* <div style={{width:"100%"}}> */}
                          <div style={{ width: "50%" }}>{cof.name}</div>
                          <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                            <div
                              // value={cof.newqty-cof.oldqty}
                              style={{ width: "30%", textAlign: "center" }}

                              value={cof.sold} placeholder="Продано">
                              {cof.sold}
                            </div>

                          </div>
                          <div style={{ width: "20%" }}>{cof.sold * cof.price - cof.sale10 * 0.1 * cof.price - cof.sale20 * 0.2 * cof.price - cof.sale30 * 0.3 * cof.price}РУБ</div>

                        </div>

                      </>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div style={{ width: "100%" }}>
                      {nocoffee.map((cof, index) => <>
                        <div className={style.itemdd} >
                          {/* <div style={{width:"100%"}}> */}
                          <div style={{ width: "50%" }}>{cof.name}</div>
                          <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                            <div
                              // value={cof.newqty-cof.oldqty}
                              style={{ width: "30%", textAlign: "center" }}

                              value={cof.sold} placeholder="Продано">
                              {cof.sold}
                            </div>

                          </div>
                          <div style={{ width: "20%" }}>{cof.sold * cof.price - cof.sale10 * 0.1 * cof.price - cof.sale20 * 0.2 * cof.price - cof.sale30 * 0.3 * cof.price}РУБ</div>

                        </div>

                      </>
                      )}
                    </div>
                  </TabPanel>
                </Tabs>



              </TabPanel>

              <TabPanel>
                <div style={{ width: "100%" }}>
                  {cmcsclad.map((cof, index) => <>
                    <div className={style.itemdd} >
                      {/* <div style={{width:"100%"}}> */}
                      <div style={{ width: "50%" }}>{cof.name}</div>
                      <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                        <div
                          // value={cof.newqty-cof.oldqty}
                          style={{ width: "30%", textAlign: "center" }}

                          value={cof.rashod} placeholder="Списано">
                          {cof.scldqty - cof.andqty + cof.prihod - cof.rashod}
                        </div>

                      </div>
                      <div style={{ width: "20%" }}>{cof.mnt}</div>

                    </div>

                  </>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div style={{ width: "100%" }}>
                  {cmcsclad.map((cof, index) => <>
                    <div className={style.itemdd} >
                      {/* <div style={{width:"100%"}}> */}
                      <div style={{ width: "50%" }}>{cof.name}</div>
                      <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                        <div
                          // value={cof.newqty-cof.oldqty}
                          style={{ width: "30%", textAlign: "center" }}

                          value={cof.prihod} placeholder="Списано">
                          {cof.prihod}
                        </div>

                      </div>
                      <div style={{ width: "20%" }}>{cof.mnt}</div>

                    </div>

                  </>
                  )}
                </div >
              </TabPanel>
            </Tabs >
          </div>
        </div>
      )}
    </>
  )
};

export default SaleReportShort;