import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { FcEngineering, FcCancel } from "react-icons/fc";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css


const EditRecieptListModal = ({ order_id, prod_id }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [newValue, setNewValue] = useState('')
  const [err, setErr] = useState('')

  const { setRecieptListModal,Notify,setReciepts,editRecieptListModal, setEditRecieptListModal, setScladOrderListModal, setSclad, editScladOrderListItemModal, setEditScladOrderListItemModal, sclad, setScladOrders, scladOrders, admin } = useContext(QtdContext);
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "gjh2hkkqw" && setEditRecieptListModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  // console.log('order_id, prod_id', order_id, prod_id)
  const prod = sclad.find(pr => pr.id == prod_id)

  const editIt = async () => {
    // e.preventDefault()
    try {
      let t = ''
      if (newValue <= 0 || newValue == '') {
        t = 'Выберите количество!'
      }
      setErr(t)
      if (!t && newValue) {
        seloading(true)
        // console.log('ask, ', order_id, prod_id, newValue)

        const newProd = await $api.post(`${BACKURL}/api/reciepts/editqty`,
          { order_id, prod_id, newValue: newValue, admin: admin.id }
        )
        setReciepts(
          newProd.data
        )

        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Ингридиент успешно обновлен!",
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        setEditRecieptListModal(false)
        setRecieptListModal(false)

      }
    } catch (e) {
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
      seloading(false)

    }
  }
  const submit = async () => {
    confirmAlert({
      title: 'Изменить количесвто ингридиента',
      message: 'Вы уверены, что хотите изменить количесвто ингридиента в товаре?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editIt()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  return (
    <>
      {editRecieptListModal && (
        <div onClick={closeModal2} id="gjh2hkkqw" className={style.modal_container}>

          <div className={style.borders}>


            <div className={style.inputtest}>
              <label >Выберите количество:</label>


              <input type="number" id="pass" name="pass" onChange={(event) => {
                setNewValue(event.target.value)


              }} />

              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </div>



            <Button
              text="Изменить"
              background="var(--primary-color)"
              color="white"
              width="100%"
              onClick={submit}
            />


          </div>

        </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default EditRecieptListModal;
