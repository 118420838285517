import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import './react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert'; // Import
import "react-datepicker/dist/react-datepicker.css";
const EditCoffeeMashinsModal = ({ id }) => {

  // console.log(id)

  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { Notify, editCoffeeMashinssModal, reciepts, coffeeMashins, setCoffeeMashins, setEditCoffeeMashinssModal, editEmployeesModal, setEditEmployeesModal, addEmployeesModal, shops, admins, setAdmins, admin } = useContext(QtdContext);
  const employee = coffeeMashins.find(pr => pr.id === id)
  console.log(employee, id)
  const coffeReciepts = reciepts.filter(pr => pr.kategory == 'Кофемашина')
  const sorted = coffeReciepts.sort((a, b) => a.name > b.name ? 1 : -1);
  const includet = employee.reportsCounters.map(pr => JSON.parse(pr))
  const ids = includet.map(oo => oo.id)
  let selected = sorted.map(gt => {
    if (ids.includes(gt.id)) {
      const includet1 = includet.find(ff => ff.id == gt.id)
      return includet1
    } else {
      return gt
    }
  })
  const [newValue, setNeValue] = useState({
    id: employee.id,
    name: employee.name,
    shop_id: employee.shop_id,
    reportsCounters: selected
    // employee.reportsCounters.map(pr=>JSON.parse(pr)),


  })
  // console.log('newValue', newValue)
  const handleRadioChange = (id) => {
    const updatedRadioItems = newValue.reportsCounters.map(item =>
      item.id === id ? { ...item, checked: !item.checked } : item);
    // console.log(id, updatedRadioItems)
    setNeValue({
      id: employee.id,
      name: newValue.name,
      shop_id: newValue.shop_id,
      reportsCounters: updatedRadioItems,


    });
  };
  const CounterChange = (id, value) => {
    const updatedRadioItems = newValue.reportsCounters.map(item =>
      item.id === id ? { ...item, counter: value } : item);
    // console.log(id, updatedRadioItems)
    setNeValue({
      id: employee.id,
      name: newValue.name,
      shop_id: newValue.shop_id,
      reportsCounters: updatedRadioItems,


    });
  };
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "editCoffeeMashinssModal33" && setEditCoffeeMashinssModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({

    login: false,
    pass: false,
    startdate: false,
    email: false,
    tg_id: false,
    phone: false,
    role: false,
    fullname: false,
    birthsday: false,

  })
  const submit = async () => {
    confirmAlert({
      title: 'Создание кофемашины',
      message: 'Вы уверены, что хотите изменить кофемашину? Вы внимательно проверили данные?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editPrice()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  const editPrice = async () => {
    try {
      // e.preventDefault()
      // console.log(newValue)
      let t = {
        name: false,
        shop_id: false,
        pass: false,
        tg_id: false,
        email: false,
        phone: false,
        role: false,
        fullname: false,
        birthsday: false,
      }

      // if (!newValue.startdate) {
      //   t.startdate = `Выберите дану приема на работу!`

      // }
      // if (!newValue.login) {
      //   t.login = `Выберите логин!`

      // }
      // if (!newValue.pass) {
      //   t.pass = `Введите пароль!`

      // }
      if (!newValue.name) {
        t.name = `Введите модель кофемашины!`

      }
      if (!newValue.shop_id) {
        t.shop_id = `Выберите точку!`

      }
      // if (!newValue.email) {
      //   t.email = `Введите email!`

      // }
      // if (!newValue.phone) {
      //   t.phone = `Выберите телефон!`

      // }
      // if (!newValue.role) {
      //   t.role = `Выберите роль!`

      // }
      // if (!newValue.fullname) {
      //   t.fullname = `Выберите ФИО!`

      // }
      // if (!newValue.birthsday) {
      //   t.birthsday = `Выберите дату рождения!`

      // }
      // console.log(newValue)
      setErr(t)
      // Notify.addNotification({
      //   title: "Ошибка!",
      //   message: "Доделать!",
      //   type: "danger",
      //   insert: "bottom",
      //   container: "bottom-center",
      //   animationIn: ["animate__animated", "animate__fadeIn"],
      //   animationOut: ["animate__animated", "animate__fadeOut"],
      //   dismiss: {
      //     duration: 5000,
      //     onScreen: true
      //   }
      // })
      console.log(t, newValue)
      if (
        !t.name &&
        !t.shop_id &&

        newValue.name &&
        newValue.shop_id

      ) {
        seloading(true)
        // const r = selectedReciepts.filter(pr => pr.checked)
        const newProd = await $api.post(`${BACKURL}/api/coffeemashins/edit/${id}`,
          { newValue: newValue, admin: admin.id }
        )
console.log(newProd.data)
        setCoffeeMashins(coffeeMashins.map(pr => {
          if (pr.id == newProd.data.id) {
            return newProd.data
          }
          else { return pr }
    }))
      setEditCoffeeMashinssModal(false)
      Notify.addNotification({
        title: "Готово!",
        message: "Кофемашина успешно изменена!",
        type: "success",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
      // // console.log(newValue)
      // const newProd = await $api.post(`${BACKURL}/api/admins/edit`,
      //   { newValue: newValue, admin: admin.id }
      // )
      // setAdmins(admins.map(pr => pr.id == id ? newProd.data : pr))
      // setServOrders(newPosts)
      // setSclad(newPosts)

      // //Проверь правильность данных тут
      // setServerProd(newPosts)
      // console.log(newProd.data)
      // setModalInfo(false)
      seloading(false)

      // localStorage.setItem("petNew", JSON.stringify(newPosts));
      setEditCoffeeMashinssModal(false)
      // alert('Добавлено!')
    }

    } catch (e) {
    seloading(false)
    Notify.addNotification({
      title: "Ошибка!",
      message: "Произошла непредвиденная ошибка!",
      type: "danger",
      insert: "bottom",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })

  }


}

const availa = coffeeMashins.map(pr => { return Number(pr.shop_id) })
const availableshops1 = availa.filter(pr => (pr != Number(newValue.shop_id)))
const availableshops = shops.filter(pr => !availableshops1.includes(pr.id))
// console.log('availableshops', availableshops)
return (
  <>

    {editCoffeeMashinssModal && (
      <div onClick={closeModal} id="editCoffeeMashinssModal33" className={style.modal_container}>

        <div className={style.borders} style={{height:'80%'}}>
          <div className={style.inputtest}>
            <label > Введите модель:</label>

            <input type="text" value={newValue.name} id="pass" name="pass" onChange={(event) => {

              setNeValue({ ...newValue, name: event.target.value });
            }} />
            {err.name && (
              <p style={{ color: "red" }}>{err.name}</p>
            )}
          </div>
          <div className={style.inputtest}>
            <label >Выберите точку:</label>

            <select name="pets" id="prod"
              value={Number(newValue.shop_id)}
              // defaultValue={newValue.shop_id}
              onChange={(event) => {
                setNeValue({ ...newValue, shop_id: event.target.value });
              }}
            >
              <option value="">--Выберите точку--</option>

              {availableshops.map(shop => {
                return (
                  <option value={shop.id}>{shop.name}</option>

                )
              })}

            </select>
            {err.shop_id && (
              <p style={{ color: "red" }}>{err.shop_id}</p>
            )}
          </div>

          <div className={style.inputtest}>
            <h5>Выберите счетчик</h5>
            <div className={style.shopsdiv} style={{ flexDirection: 'column' }}>

              <br></br>
              {newValue.reportsCounters.map((shop, index) => (
                <>
                  <div className={style.coffees}>
                    <div className={style.form_radio_btn}
                      onClick={() => handleRadioChange(shop.id)}
                    >
                      <label htmlFor={`radio_${shop.id}`}
                        style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                      </label>
                      <input type="radio" name="radio_group"
                        checked={shop.checked}
                        style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                      // className={style.form_radio_btn}
                      />
                    </div>
                  </div>

                  {shop.checked && (<>
                    <label > Введите показание счетчика:</label>

                    <input type="text" id="pass" name="pass"
                      value={shop.counter}
                      onChange={(event) => CounterChange(shop.id, event.target.value)
                      }
                    />
                    {err.fullname && (
                      <p style={{ color: "red" }}>{err.fullname}</p>
                    )}
                  </>
                  )}
                </>



              )
              )}
            </div>






          </div>
          <Button
            text="Изменить"
            background="var(--primary-color)"
            color="white"
            // width="100%"
            onClick={submit}
          />
        </div>

      </div>
    )}
    {loading && (
      <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
    )}
  </>
);
};

export default EditCoffeeMashinsModal;
