import React from "react";
import style from "./Home.module.css";
import { Routes, Route, useParams, Navigate, useNavigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
// import { useNavigate } from "react-router-dom";

import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu";
import AddShopModal from "../../../components/Modals/AddShopModal";

const Shops = () => {
  const navigate = useNavigate()
  const [check, setCheck] = useState(false)
  const [color, setColor] = useState([]
    // {
    //   background:
    //   `linear-gradient(#ffffff, #fff0cb) padding-box,
    //   linear-gradient(#ffb700, #feb7c8) border-box`
    // }
  )
  const { admin, shops, smenas, addShopModal, setAddShopModal, setDashboarPage, scladOrders } = useContext(QtdContext);
  if (!(admin.role == 'Кладовщик' || admin.role == 'AllRights' || admin.role == 'Администратор' || admin.role == 'Бариста')) {
    setDashboarPage('Home')

    return <Navigate to="/" replace />;
  } else {

    const addShop = () => {
      setAddShopModal(true)
    }
    const handleClick = (id) => {
      localStorage.setItem('dashboardpage', `ShopPage:${id}`)

      setDashboarPage(`ShopPage:${id}`)
    }
    const smena = smenas.find(pr => (pr.admin_id == admin.id && pr.open == true))
    let sorted = []
    if (smena && admin.role == 'Бариста') {
      sorted = shops.filter(pr => pr.id == smena.shop_id)
    } else if(!smena && admin.role == 'Бариста') {
      const smenaq = smenas.filter(pr => pr.open == true)
      const smenarr = smenaq.map(pr=>pr.shop_id)

      sorted = shops.filter(pr => !smenarr.includes(pr.id))

    }  else
    {
      sorted = shops.sort((a, b) => a.name > b.name ? 1 : -1);

    }
    sorted.sort((a, b) => a.name > b.name ? 1 : -1);
    const checkOrders = () => {
      const test = scladOrders.map(pr => {
        // console.log(pr)
        if (pr.status == 'Передан на склад!') {
          return pr.shop_id
        }
      })
      // console.log(test)
      let shopsMakers = []
      // shops.map(shop => {
      //   console.log(shop.id)
      //   if (test.includes(shop.id)) {
      //     return `${shop.name} - Заявка Сделана!`
      //   } else {
      //     return `${shop.name} - Заявки Нет!`

      //   }
      // })

      for (let i = 0; i < shops.length; i++) {
        // console.log(shops[i].id)
        if (test.includes(String(shops[i].id))) {
          // setColor({
          //   id: shops[i].id,
          //   style: {
          //     background: `linear-gradient(#fff,#fff0cb) padding-box,linear-gradient(#00ff0b,#94ee88)`
          //   }
          // }
          // )
          shopsMakers.push({
            id: shops[i].id,
            style: {
              background: `linear-gradient(#fff,#fff0cb) padding-box,linear-gradient(#00ff0b,#94ee88)`
            }
          })
        } else {
          // setColor({
          //   id: shops[i].id,

          //   style: {
          //     background: `linear-gradient(#fff,#fff0cb) padding-box,linear-gradient(#ff0036,#feb7c8)`
          //   }
          // }
          // )
          shopsMakers.push({
            id: shops[i].id,

            style: {
              background: `linear-gradient(#fff,#fff0cb) padding-box,linear-gradient(#ff0036,#feb7c8)`
            }
          })

        }
      }

      setColor(shopsMakers)
    }



    return (<>

      <div className={style.sclad_container}>
        {(admin.role == 'AllRights' || admin.role == 'Администратор') && (<>
          <Button text='Создать новую точку' background='orange' onClick={addShop} />
          <Button text='Проверить Заказы' background='#89f08c' onClick={checkOrders} />
        </>
        )}
        <div className={style.testdd}>
          <div className={style.shopslist}>

            {sorted.map(shop => {
              const orders = scladOrders.filter(pr => (pr.shop_id == shop.id && pr.status != 'Выполнен!'))

              // const color
              let defcolor = color
              if (color.length > 0) {
                const thiscolor = color.find(pr => pr.id == shop.id)
                defcolor = thiscolor.style
              }

              return (

                <div key={shop.id}
                  style={defcolor}
                  className={style.borders} onClick={() => handleClick(shop.id)}>


                  <div>
                    {orders.length > 0 && (
                      <span className={style.badge}>{orders.length}</span>

                    )}
                  </div>
                  <div>
                    {shop.name}
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      </div>
      {addShopModal && (
        <AddShopModal

        />

      )}


    </>
    );
  }

};

export default Shops;
