import React from "react";
import style from "./Home.module.css";
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext.js";
import Button from '../../../components/Button/Button.js'
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FcCancel, FcEngineering } from "react-icons/fc";
import $api from '../../../http/index.ts'
import './react-confirm-alert.css'; // Import css
import styled from "styled-components";

import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu.js";
// import AddProductModal from "../../../components/Modals/AddProductModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import AddEmployeesModal from "../../../components/Modals/Emloyees/AddEmployeesModal.js";
import EditEmployeesModal from "../../../components/Modals/Emloyees/EditEmployeesModal.js";
import CreateCoffeeMashinsModal from "../../../components/Modals/CoffeeMashins/CreateCoffeeMashinsModal.js";
import { CiMail } from "react-icons/ci";
import EditCoffeeMashinsModal from "../../../components/Modals/CoffeeMashins/EditCoffeeMashinsModal.js";
import CoffeeMasinsListModal from "../../../components/Modals/CoffeeMashins/CoffeeMasinsListModal.js";
// import / from "../../../../../citycoffee_back/src/models/shops.js";

const CoffeeMashins = () => {
  const { smenas, saleReports,Notify, coffeeMashinsListModal, setCoffeeMashinListsModal, addCoffeeMashinssModal, setAddCoffeeMashinssModal, editCoffeeMashinssModal, setEditCoffeeMashinssModal, shops, coffeeMashins, setCoffeeMashins, createCoffeeMashins, setCreateCoffeeMashins, editEmployeesModal, setEditEmployeesModal, admin, admins, setAdmins, addEmployeesModal, setAddEmployeesModal, setDashboarPage, setAdmin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [id, setId] = useState('')

  if (!(admin.role == 'AllRights' || admin.role == 'Администратор')) {
    setDashboarPage('Home')
    // setAdmin({ auth: false});
    return <Navigate to="/" replace />

  } else {
    // console.log(admin.role != 'GOD' && admin.role != 'Администратор')
    // useEffect(()=>{})
    const users = admins.filter(pr => pr.role != 'AllRights')
    const sorted = users.sort((a, b) => a.fullname > b.fullname ? 1 : -1);
    const addProduct = () => {
      setCreateCoffeeMashins(true)
    }
    const saved = saleReports.find(pr => pr.status == 'Сохранен')

    // useEffect(())
    const showList = (id) => {
      console.log(id)

      setId(id)
      setCoffeeMashinListsModal(true)
    }
    const OpnaEditModal = (id) => {
      setId(id)
      setEditCoffeeMashinssModal(id)
    }
    const deleteIt = async (id) => {
      try {
        seloading(true)
        const deleteProd = await $api.get(`${BACKURL}/api/coffeemashins/delete/${id}`)
        setCoffeeMashins(coffeeMashins.filter(item => item.id != id))
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Кофемашина успешно удалена из системы!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });

      } catch (e) {
        console.log(e)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      }
    }

    const submit = async (func, id) => {
      confirmAlert({
        title: 'Удалить сотрудника',
        message: 'Вы уверены, что хотите удалить кофемашину из системы?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };



    const TCheckBox = styled.input.attrs(props => ({
      type: "checkbox"
    }))`
      display: none;
    `;
    const TCheckBoxWrapper = styled.div < { checked: boolean } > `
      background: ${p => (p.checked ? "#3f81ff" : "#ececec")};
      border-radius: 4rem;
      height: 32px;
      width: 68px;
      position: relative;
      transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
      cursor: pointer;
    `;

    const TCheckBoxToggle = styled.div < { checked: boolean } > `
      background: #fff;
      border-radius: 4rem;
      height: 26px;
      width: 26px;
      top: 3px;
      left: ${p => (p.checked ? "38px" : "4px")};
      position: absolute;
      transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
    `;
    const ToggleCheckbox = props => {
      return (
        <TCheckBoxWrapper checked={props.checked} onClick={props.onChange}>
          <TCheckBoxToggle checked={props.checked} />
          <TCheckBox {...props} />
        </TCheckBoxWrapper>
      );
    };
    const toggle = async (id) => {
      try {
        seloading(true)
        // const deleteProd = await $api.get(`${BACKURL}/api/products/switch/${id}`)
        // setSclad(sclad.map(item => item.id == id ? deleteProd.data : item))
        seloading(false)
      } catch (e) {
        console.log(e)
      }
    };
    return (<>

      <div className={style.sclad_container}>
        <Button text='Создать Кофемашину' background='orange' onClick={addProduct} />

        <div className={style.table2}>
          <table className={style.table}>
            <thead className={style.tableth}>

              <tr>
                <td className={style.tabletd}>№</td>
                <td className={style.tabletd}>Модель</td>
                <td className={style.tabletd}>Точка</td>
                <td className={style.tabletd}>Список Счетчиков</td>
                {/* <td className={style.tabletd}>on/off</td> */}
                <td className={style.tabletd}>Действия</td>

              </tr>
            </thead>
            <tbody>
              {coffeeMashins.map(coffemashin => {
                const shop = shops.find(pr => pr.id == coffemashin.shop_id)
                return (<tr>
                  <td className={style.tabletd}>{coffemashin.id}</td>
                  <td className={style.tabletd}>{coffemashin.name}</td>
                  <td className={style.tabletd}>{shop.name}</td>
                  <td className={style.tabletd}>
                    <Button text='Посмотреть' background='orange'
                      onClick={() => showList(coffemashin.id)}
                    />

                  </td>
                  {/* <td className={style.tabletd}>
                    <div>
                      <ToggleCheckbox 
                      // checked={prod2.showed} onChange={() => toggle(prod2.id)}
                       />
                    </div>
                  </td> */}
                  <td className={style.tabletd} style={{
                    'display': 'flex',
                    'justifyContent': 'space-around'
                  }}>
                    {/* {!saved && ( */}
                      <div className={style.cell}>
                        <FcEngineering
                          onClick={() =>
                            OpnaEditModal(coffemashin.id)
                          }
                        />
                      </div>
                    {/* )} */}

                    <div className={style.cell}>

                      <FcCancel onClick={() => submit(deleteIt, coffemashin.id)} />
                    </div>
                  </td>
                </tr>)
              }
              )}



            </tbody>
          </table>
        </div>

      </div>
      {coffeeMashinsListModal && (
        <CoffeeMasinsListModal
          id={id}>

        </CoffeeMasinsListModal>
      )}
      {editCoffeeMashinssModal && (
        <EditCoffeeMashinsModal
          id={id}

        />

      )}
      {createCoffeeMashins && (
        <CreateCoffeeMashinsModal

        />

      )}

      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}


    </>
    );
  }


};

export default CoffeeMashins;
