import React from 'react'
import Button from '../../components/Button/Button'
// import 'react-notifications-component/dist/theme.css'
// import 'animate.css/animate.min.css';
// import Button from '../../Button/Button'
import style from './Admin.module.css'
// import { ReactNotifications } from 'react-notifications-component'
import axios from 'axios';
import { QtdContext } from "../../context/qtdContext";
import { useEffect, useState, useContext } from "react";
import $api from "../../http/index.ts";
import { BACKURL } from '../../conf.js'
import Loading from 'react-fullscreen-loading';
import Dashboard from '../Admin/DashBoard/Dashboard';
import LinksMenu from '../../components/Header/LinksMenu.js';
const Auth = () => {
    const { Notify, admin, setAdmin, setAdmins, setSclad, setShops } = useContext(QtdContext);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, [])

    const [logdata, setLogData] = useState({
        login: '',
        pass: ''
    })
    const [err, setErr] = useState({
        login: '',
        pass: '',
        answ: ''
    })
    // console.log(admin)
    const LogIn = async () => {
        try {
            let t = {
                login: '',
                pass: '',
                answ: ''
            }
            if (!logdata.login) {
                t.login = `Введите логин!`

            }
            if (!logdata.pass) {
                t.pass = `Введите пароль!`

            }
            setErr(t)
            setLoading(true)
            const response = await $api.post(`${BACKURL}/api/login`, logdata)
            localStorage.setItem('token', response.data.accessToken);
            setAdmin({ auth: true, fullname: response.data.user.fullname, id: response.data.user.id, user: response.data.user.login, role: response.data.user.role });

            setLoading(false)

            Notify.addNotification({
                title: "Готово!",
                message: "Вы успешно вошли в систему!",
                type: "success",
                insert: "bottom",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            // setAdmin(log);
            // setAuth(true)

        } catch (e) {
            // console.log(e.response?.data?.message);
            setErr({
                login: '',
                pass: '',
                answ: e.response?.data?.message
            })

            setLoading(false)
            Notify.addNotification({
                title: "Ошибка!",
                message: e.response?.data?.message,
                type: "warning",
                insert: "bottom",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            })
            // console.log(e)
        }



    }
    return (
        <>
            {/* <ReactNotifications /> */}
            {!admin.auth && (<>
                <h1 text="Вход" />

                <div className={style.container}>
                    <div className={style.search}>
                        <div className={style.imglogo}>
                            <img src="https://api.bobcrm.ru/static/logo.png" />
                        </div>
                        <br></br>
                        <form className={style.form}>
                            {/* <label ><h4>Введите логин!</h4></label> */}
                            <input placeholder='Логин' type="text" style={{ textAlign:'center',borderRadius: '30px' }} id="n" name="n" onChange={(event) => {
                                setLogData({ ...logdata, login: event.target.value });
                            }} />
                            {err.login && (
                                <p style={{ color: "red" }}>{err.login}</p>
                            )}
                            <br />
                            {/* <label ><h4>Введите пароль!</h4></label> */}
                            <input  placeholder='Пароль' style={{marginBottom:'15px', textAlign:'center',borderRadius: '30px' }} type="password" name="password" autocomplete="on"
                                onChange={(event) => {
                                    setLogData({ ...logdata, pass: event.target.value });
                                }} />
                            {err.pass && (
                                <p style={{ color: "red" }}>{err.pass}</p>
                            )}
                        </form>
                        {!loading && (<>
                            <Button text='Войти' background='orange' onClick={LogIn} />
                            {err.answ && (
                                <p style={{ color: "red" }}>{err.answ}</p>
                            )}
                        </>
                        )}
                        {loading && (
                            <Button text='Входим в систему...' disabled={true} />

                        )}

                    </div>


                </div>
            </>
            )}
            {admin.auth && (<>
                <LinksMenu></LinksMenu>
                <Dashboard></Dashboard>
            </>
            )}
            {loading && (
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
        </>
    )
}

export default Auth