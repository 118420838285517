import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
import { QtdContext } from "../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../http/index.ts'
import { BACKURL } from '../../conf.js'
import Loading from 'react-fullscreen-loading';
import { FcCancel, FcServices } from "react-icons/fc";
import { confirmAlert } from 'react-confirm-alert'; // Import

const MakeOrderModal = ({ shop_id }) => {
  console.log(shop_id)

  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAutd } = useContext(QtdContext);

  const { Notify, setScladHistory, sclad, setSclad, admin, scladOrders, setScladOrders, setRashodModal, makeOrderModal, setMakeOrderModal } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({
    admin_id: admin.id,
    shop_id: shop_id,
    // list: '',
    prod_id: '',
    qty: '',
    step: '',
    stepQty: ''
  })
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    target.id === "c2ontainer12464q" && setMakeOrderModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({
    prod_id: false,
    admin_id: false,
    qty: false,
    shop_id: false,
    step: false,
    stepQty: false

  })

  const [prodArr, setProdArr] = useState([])
  const dlt = (prod_id) => {
    setProdArr(prodArr.filter(pr => pr.prod_id != prod_id))
  }
  const addProd = async (e) => {
    e.preventDefault()

    let t = {
      prod_id: false,
      admin_id: false,
      qty: false,
      shop_id: false,
      step: false,
      stepQty: false
    }
    if (!newValue.prod_id) {
      t.prod_id = `Выберите товар!`

    }
    if (!newValue.qty || Number(newValue.qty) < 0) {
      t.qty = `Введите положительное число!`

    }
    setErr(t)
    if (
      !t.prod_id &&
      !t.qty &&
      newValue.admin_id,
      newValue.prod_id,
      newValue.qty

    ) {
      const product = sclad.find(pr => pr.id == newValue.prod_id)

      setProdArr([...prodArr, {
        prod_id: newValue.prod_id,
        qty: newValue.qty,
        name: product.name,
        mnt: product.mnt,
      }])

      setNeValue({
        admin_id: admin.id,
        shop_id: shop_id,
        prod_id: '',
        qty: '',
        step: '',
        stepQty: ''
      })
    }
  }
  const editPrice = async (
    // e
  ) => {
    try {
      // e.preventDefault()

      let t = {
        prod_id: false,
        admin_id: false,
        qty: false,
        shop_id: false,
        list: false,
      }






      if (!newValue.shop_id) {
        t.shop_id = `Не выбрана точка!`

      }

      if (!newValue.admin_id) {
        t.admin_id = `Не указан тип!`

      }
      if (!prodArr.length > 0) {
        t.list = `Выберите продукт!`

      }

      setErr(t)
      if (
        !t.shop_id &&
        !t.admin_id &&
        !t.list &&
        newValue.prod_id,
        newValue.shop_id

      ) {
        seloading(true)
        // (newValue)


        // (newValue)
        const newProd = await $api.post(`${BACKURL}/api/scladorder/create`,
          { prodArr: prodArr, newValue: newValue, admin: admin.user }
        )

        // setScladHistory([...scladHistory, newProd.data.scladhistory])
        setScladOrders([...scladOrders, newProd.data.scladOrder])
        setSclad(newProd.data.sclad)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Заказ успешно создан!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setMakeOrderModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })

    }


  }

  const setProd = (prod_id) => {
    const thisProd = sclad.find(item => item.id == prod_id)

    const result = prodArr.find(item => item.prod_id == thisProd.id)
    // (thisProd, !result)
    if (!result) {
      setNeValue({ ...newValue, prod_id: prod_id, step: thisProd.step, stepQty: thisProd.qty })

    } else {
      setErr({ prod_id: 'Данный товар уже был выбран!' })
    }
    // if()

  }
  const submit = async (func) => {
    confirmAlert({
      title: 'Заказать товар со склада?',
      message: 'Вы уверены, что добавили все необходимые товары?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await func()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };


  const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory)));

  // (uniqueSerials, sclad)
  function checkDuplicateIds(array1, array2) {
    const ids1 = array1.map(obj => obj.id);
    const ids2 = array2.map(obj => obj.prod_id);

    for (let i = 0; i < ids1.length; i++) {
      if (ids2.includes(ids1[i])) {
        return true;
      }
    }

    return false;
  }
  const qeroFilter = sclad.filter(pr => ((Number(pr.scldqty) - Number(pr.scldbron) > 0) && !checkDuplicateIds(sclad, prodArr)))
  const sorted = qeroFilter.sort((a, b) => a.name > b.name ? 1 : -1);
  const setQty = (qty) => {
    const prod = sclad.find(pr => pr.id == newValue.prod_id)

    if (prod.scldqty - prod.scldbron - qty >= 0) {
      setNeValue({ ...newValue, qty: qty });

    } else {
      setErr({ qty: `Всего на складе доступно ${prod.scldqty - prod.scldbron}${prod.mnt} ${prod.name}!` })
    }

  }

  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {makeOrderModal && (
        <div onClick={closeModal} id="c2ontainer12464q" className={style.modal_container}>



          {/* /// */}
          <div className={style.borders}>
            {prodArr.length > 0 && (
              <div className={style.inputtest}>
                <ol>
                  {prodArr.map(pr => (
                    <li>
                      <div className={style.list}>
                        <div className={style.itemlist}>
                          {pr.name}  x  {pr.qty}{pr.mnt}
                        </div>
                        <div className={style.actionlist}>
                          <FcCancel onClick={() => dlt(pr.prod_id)} />

                        </div>
                      </div>
                    </li>
                  ))}
                </ol>

              </div>
            )}

            <div className={style.inputtest}>

              <label >Выберите товар:</label>

              <select name="pets" id="prod"
                value={newValue.prod_id}
                onChange={(event) => {
                  setProd(event.target.value)

                }}
              >
                <option value="">--Выберите товар--</option>

                {uniqueSerials.map(serials => {
                  if (shop_id != 14) {
                    const table = sorted.filter(prod => (prod.kategory === serials && prod.showed))

                    return (<>
                      <optgroup label={`${serials}`}>
                        {table.map(prod => (
                          <option value={`${prod.id}`}>{prod.name}</option>

                        ))}
                      </optgroup></>
                    )
                  } else {
                    const table = sorted.filter(prod => (prod.kategory === serials && prod.showed && prod.name != 'Стаканы 16oz (чай, американо)' && prod.name != 'Стаканы 8oz (чай, американо)' && prod.name != 'Крышки 80мм' && prod.name != 'Крышки 90мм'))

                    return (<>
                      <optgroup label={`${serials}`}>
                        {table.map(prod => (
                          <option value={`${prod.id}`}>{prod.name}</option>

                        ))}
                      </optgroup></>
                    )
                  }

                }
                )}


              </select>
              {err.prod_id && (
                <p style={{ color: "red" }}>{err.prod_id}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Выберите количество:</label>

              <select name="pets" id="qty"
                value={newValue.qty}
                onChange={(event) => {
                  setQty(event.target.value)
                }}
              >
                <option value="">--Выберите количесвто--</option>
                {newValue.step && (<>
                  {[...Array(Number(newValue.stepQty))].map((item, index) => <option value={`${newValue.step * (index + 1)}`}>{newValue.step * (index + 1)}{newValue.mnt}</option>)}
                </>
                )}
                {/* {

              
sclad.find(prod => prod.id === newValue.prod_id).then((item)=>)

                      return (<>
                      <optgroup label={`${serials}`}>
                        {table.map(prod => (
                          <option value={`${prod.id}`}>{prod.name}</option>

                        ))}
                        </optgroup></>
                      )
                    } */}


              </select>
              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </div>
            <Button
              text="Добавить товар"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={addProd}
            />




            {prodArr.length > 0 && (<>
              <Button
                text="Заказать"
                background="var(--primary-color)"
                color="white"
                // width="100%"
                onClick={() => submit(editPrice)}
              />
              {err.list && (
                <p style={{ color: "red" }}>{err.list}</p>
              )}</>
            )}

          </div>


        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default MakeOrderModal;
