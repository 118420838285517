import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';

const EditProductModal = ({ id }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const { setScladShops, Notify, editProductModal, setEditProductModal, addProductModal, shops, setAddProductModal, sclad, setSclad, admin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "editProductModal" && setEditProductModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const product = sclad.find(pr => pr.id == id)
  const [newValue, setNeValue] = useState(product)

  const [err, setErr] = useState({
    name: false,
    kategory: false,
    step: false,
    qty: false,
    mnt: false,
  })

  const editPrice = async (e) => {
    try {
      e.preventDefault()

      let t = {
        name: false,
        kategory: false,
        step: false,
        qty: false,
        mnt: false,
      }


      if (!newValue.qty) {
        t.qty = `Выберите максимальное количество!`

      }
      if (!newValue.name) {
        t.name = `Введите наименование!`

      }

      if (!newValue.kategory) {
        t.kategory = `Выберите категорию!`

      }
      if (!newValue.mnt) {
        t.mnt = `Выберите еденицу измерения!`

      }
      if (!newValue.step) {
        t.step = `Выберите кратность!`

      }

      setErr(t)
      if (
        !t.name &&
        !t.qty &&
        !t.kategory &&
        !t.mnt &&
        !t.step &&
        newValue.name,
        newValue.kategory,
        newValue.mnt,
        newValue.step,
        newValue.qty
      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/products/edit/${id}`,
          { newValue: newValue, admin: admin.id }
        )
        setSclad(sclad.map(pr => {
          if (pr.id == id) {
            return newProd.data.sclad
          } else {
            return pr
          }
        }))
        setScladShops(newProd.data.scldshops)
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)

        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setEditProductModal(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Продукт успешно изменен!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        // alert('Добавлено!')
      }

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })

    }


  }


  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {editProductModal && (
        <div onClick={closeModal} id="editProductModal" className={style.modal_container}>

          <div className={style.borders}>

            <div className={style.inputtest}>

              <label >Введите наименование:</label>

              <input type="text" id="pass" value={newValue.name} name="pass" onChange={(event) => {
                setNeValue({ ...newValue, name: event.target.value });
              }} />
              {err.name && (
                <p style={{ color: "red" }}>{err.name}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Выберите категорию:</label>

              <select name="pets" id="prod"
                value={newValue.kategory}
                onChange={(event) => {
                  setNeValue({ ...newValue, kategory: event.target.value });
                }}
              >
                <option value="">--Выберите категорию--</option>
                <option value="Сироп">Сироп</option>
                <option value="Кофе/Какао">Кофе/Какао</option>
                <option value="Чай">Чай</option>
                <option value="Стаканы/Крышки">Стаканы/Крышки</option>
                <option value="Канцелярия">Канцелярия</option>
                <option value="Прочее">Прочее</option>
                <option value="Ресурсы">Ресурсы</option>
              </select>
              {err.kategory && (
                <p style={{ color: "red" }}>{err.kategory}</p>
              )}
            </div>

            <div className={style.inputtest}>
              <label >Выберите ед:</label>

              <select name="pets" id="prod"
                value={newValue.mnt}
                onChange={(event) => {
                  setNeValue({ ...newValue, mnt: event.target.value });
                }}
              >
                <option value="">--Выберите ед--</option>
                <option value="шт">шт</option>
                <option value="кг">кг</option>
                <option value="г">г</option>
                <option value="л">л</option>
                <option value="уп">уп</option>
                <option value="рул">рул</option>
              </select>
              {err.mnt && (
                <p style={{ color: "red" }}>{err.mnt}</p>
              )}
            </div>

            <div className={style.inputtest}>

              <label >Выберите кратность:</label>

              <select name="pets" id="prod"
                value={newValue.step}
                onChange={(event) => {
                  setNeValue({ ...newValue, step: event.target.value });
                }}
              >
                <option value="">--Выберите ед--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="900">900</option>
                <option value="1000">1000</option>
                <option value="1200">1200</option>
                <option value="1250">1250</option>
                <option value="1350">1350</option>
                <option value="3000">3000</option>
                <option value="5000">5000</option>
              </select>
              {err.step && (
                <p style={{ color: "red" }}>{err.step}</p>
              )}
            </div>
            {newValue.step && (<>
              <div className={style.inputtest}>


                <label >Выберите максимальное количество:</label>

                <select name="pets" id="prod"
                  value={newValue.qty}
                  onChange={(event) => {
                    setNeValue({ ...newValue, qty: event.target.value });
                  }}
                >
                  <option value="">--Выберите--</option>
                  <option value="1">{Number(newValue.step)}</option>
                  <option value="2">{Number(newValue.step) * 2}</option>
                  <option value="3">{Number(newValue.step) * 3}</option>
                  <option value="4">{Number(newValue.step) * 4}</option>
                  <option value="5">{Number(newValue.step) * 5}</option>
                  <option value="6">{Number(newValue.step) * 6}</option>
                  <option value="7">{Number(newValue.step) * 7}</option>
                  <option value="8">{Number(newValue.step) * 8}</option>
                  <option value="9">{Number(newValue.step) * 9}</option>
                  <option value="10">{Number(newValue.step) * 10}</option>
                  <option value="11">{Number(newValue.step) * 11}</option>
                  <option value="12">{Number(newValue.step) * 12}</option>
                  <option value="13">{Number(newValue.step) * 13}</option>
                  <option value="14">{Number(newValue.step) * 14}</option>
                  <option value="15">{Number(newValue.step) * 15}</option>
                  <option value="16">{Number(newValue.step) * 16}</option>
                  <option value="17">{Number(newValue.step) * 17}</option>
                  <option value="18">{Number(newValue.step) * 18}</option>
                  <option value="19">{Number(newValue.step) * 19}</option>
                  <option value="20">{Number(newValue.step) * 20}</option>
                </select>
                {err.qty && (
                  <p style={{ color: "red" }}>{err.qty}</p>
                )}

              </div>
            </>)}

            <Button
              text="Добавить Продукт"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={editPrice}
            />


          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default EditProductModal;
