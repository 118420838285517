import React from "react";
import style from "./Home.module.css";
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
import { confirmAlert } from 'react-confirm-alert'; // Import

import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu";
import AddShopModal from "../../../components/Modals/AddShopModal";
import MakeOrderModal from "../../../components/Modals/MakeOrderModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CiFloppyDisk } from "react-icons/ci";
import { FcCancel, FcServices, FcInfo } from "react-icons/fc";
import { RiFileDownloadLine } from "react-icons/ri";
import ScladOrderListModal from "../../../components/Modals/Sclad/ScladOrderListModal.js";
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import ScladSelectedOrdersModal from "../../../components/Modals/Sclad/ScladSelectedOrdersModal.js";

const AllOrders = () => {
  // let { id } = useParams();
  // console.log(id.slice(1))
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

 
  const { Notify, scladSelectedOrdersModal, setScladSelectedOrdersModal, setAdmin, setDashboarPage, admin, shops, setScladHistory, setScladOrders, setSclad, dashboarPage, scladOrders, admins, makeOrderModal, setMakeOrderModal, scladOrderListModal, setScladOrderListModal } = useContext(QtdContext);
  const [itemlist, setItemlist] = useState([])
  const [orderId, setOrderId] = useState({})
  const [loading, setLoading] = useState(false)
  const [datepicker, setdatepicker] = useState(false)
  const [statusS, setStatusS] = useState('')
  const [selectShop, setSelectShop] = useState(null)
   const handleDateChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // setdatepicker(false)
  };
  if (!(admin.role == 'AllRights' || admin.role == 'Администратор' || admin.role == 'Кладовщик' || admin.role == 'Доставщик')) {
    setDashboarPage('Home')
    return <Navigate to="/" replace />;

  } else {
    const showList = (listik, id) => {
      setOrderId(id)
      setItemlist(listik)
      setScladOrderListModal(true)
    }
    // console.log(dashboarPage.split(':')[1])
    // console.log(shop, shops)
    // const shopOrders = scladOrders.filter(pr => pr.shop_id == dashboarPage.split(':')[1])
    // console.log(shopOrders)

    // const [thisshop, ==]
    const uniqueSerials = ['Передан на склад!', 'Собирается!', 'В доставке!', 'Выполнен!']
    // Array.from(new Set(scladOrders.map(item => item.status)));
    // const checkOrders = () => {
    //   const test = scladOrders.map(pr => {
    //     // console.log(pr)
    //     if (pr.status == 'Передан на склад!') {
    //       return pr.shop_id
    //     }
    //   })
    //   // console.log(test)
    //   let shopsMakers = []
    //   // shops.map(shop => {
    //   //   console.log(shop.id)
    //   //   if (test.includes(shop.id)) {
    //   //     return `${shop.name} - Заявка Сделана!`
    //   //   } else {
    //   //     return `${shop.name} - Заявки Нет!`

    //   //   }
    //   // })

    //   for (let i = 0; i < shops.length; i++) {
    //     // console.log(shops[i].id)
    //     if (test.includes(String(shops[i].id))) {
    //       shopsMakers.push(`${shops[i].name} - Заявка Сделана!`)
    //     } else {
    //       shopsMakers.push(`${shops[i].name} - Заявки Нет!`)

    //     }
    //   }

    //   console.log(shopsMakers)
    // }
    const updateStatus = async (id, serials) => {
      let newStatus = ''
      switch (true) {
        case (serials == 'Передан на склад!'):
          newStatus = 'Собирается!'
          break
        case (serials == 'Собирается!'):
          newStatus = 'В доставке!'
          break
        case (serials == 'В доставке!'):
          newStatus = 'Выполнен!'
          break
      }
      // console.log(id, serials, newStatus)

      try {
        setLoading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/scladorder/updatestatus`,
          { id: id, newStatus: newStatus, admin: admin.id }
        )
        setScladHistory(newProd.data.scladHistory)

        setSclad(newProd.data.sclad)
        setScladOrders(newProd.data.scladOrder)
        // setSclad(newPosts)
        Notify.addNotification({
          title: "Готово!",
          message: "Статус заказа успешно обновлен!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        setLoading(false)
      } catch (e) {
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        // console.log(e)
      }
    }

    // const AllOrdersToSclad = async (e) => {

    // }
    const submit = async (func, id, serials) => {
      confirmAlert({
        title: 'Обновить статус ордера?',
        message: 'Вы уверены, что хотите обновить статус заказа?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id, serials)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
    const downloadOrder = async (id, name, data) => {
      // $api.get(`${BACKURL}/api/xlsx/download/${id}`)
      try {
        setLoading(true)
        // const order = scladOrders.find(pr=>pr.id==id)
        // Отправляем запрос на сервер для скачивания Excel-файла
        const response = await $api.get(`${BACKURL}/api/xlsx/download/${id}`, {
          responseType: 'blob', // Указываем, что ожидаем blob в ответе
        })

        // Создаем ссылку для скачивания и нажимаем на нее
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `Накладная №${id} - ${name} от ${data}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Вы можете найти накладную в загрузках!",
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } catch (error) {
        setLoading(false)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        console.error('Error:', error);
      }

    }

    const downloadOrders = async () => {
      // $api.get(`${BACKURL}/api/xlsx/download/${id}`)
      try {
        setLoading(true)
        // const order = scladOrders.find(pr=>pr.id==id)
        // Отправляем запрос на сервер для скачивания Excel-файла
        const response = await $api.post(`${BACKURL}/api/xlsx/downloadmouth/${selectShop}`, { startDate, endDate }, {
          responseType: 'blob', // Указываем, что ожидаем blob в ответе
        })
        const shop = shops.find(pr => pr.id == selectShop)
        // Создаем ссылку для скачивания и нажимаем на нее
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `Отчет об отгрузках(${shop.name}).xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Вы можете найти накладную в загрузках!",
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } catch (error) {
        setLoading(false)
        Notify.addNotification({
          title: "Ошибка!",
          message: "Произошла непредвиденная ошибка!",
          type: "danger",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        console.error('Error:', error);
      }

    }
    const openAllOrdersModal = (status) => {
      setStatusS(status)
      setScladSelectedOrdersModal(true)
    }

    return (<>
      <div className={style.sclad_container}>
        <div>
          {/* {!datepicker && !startDate&&!endDate&& ( */}
            <Button text='Выбрать даты' background='#89f08c' onClick={() => setdatepicker(true)} />

          {/* )} */}
             {/* {!datepicker && startDate&&endDate&& ( */}
            <Button text={`${startDate}-${endDate}`} background='#89f08c' onClick={() => setdatepicker(true)} />

          {/* )} */}
          {datepicker && (
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          )} 
          <select value={selectShop} onChange={(e) => setSelectShop(e.target.value)}>
          <option value={null}>Выберите точку</option>
            {shops.map((sh) => <option key={sh.id} value={sh.id}>{sh.name}</option>)}
          </select>
          {selectShop && startDate && endDate && (
            <Button text='Выгрузить' background='#89f08c' onClick={downloadOrders} />

          )}

        </div>
        <div className={style.sclad_container2}>
          <Tabs >
            <TabList>
              {uniqueSerials.map(serials => {
                const one = scladOrders.filter(pr => pr.status == serials && pr.status != 'Выполнен!')

                return <Tab key={serials}>  {`${serials}`}
                  {one.length > 0 && (
                    <span className={style.badge} style={{ 'marginLeft': '15px' }}>{one.length}</span>

                  )}

                </Tab>
              }
              )}


            </TabList>
            {uniqueSerials.map(serials => {
              // console.log(serials, shopOrders)
              const table2 = scladOrders.filter(prod => prod.status === serials)

              const table = table2.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              return (<>
                <TabPanel>
                  {/* {serials == 'Передан на склад!' && (
                    <div>
                      <Button text='Собрать Заказы' background='#89f08c'
                      onClick={()=>openAllOrdersModal(serials)}
                      />

                    </div>
                  )} */}
                  {serials == 'Собирается!' && table.length > 0 && (
                    <div>

                      <Button text='Общая накладная' background='#89f08c'
                        onClick={() => openAllOrdersModal(serials)}
                      />

                    </div>
                  )}

                  <div key={`${serials}`} className={style.table2}>
                    <table className={style.table}>
                      <thead className={style.tableth}>

                        <tr>
                          <td className={style.tabletd}>№</td>
                          <td className={style.tabletd}>Точка</td>
                          <td className={style.tabletd}>Сотрудник</td>
                          <td className={style.tabletd}>Список</td>
                          <td className={style.tabletd}>Дата</td>
                          <td className={style.tabletd}>Накладная</td>
                          {serials != 'Выполнен!' && (serials == 'Передан на склад!' || serials == 'Собирается!') && admin.role != 'Доставщик' && admin.role != 'Бариста' && (
                            <td className={style.tabletd}>Далее</td>

                          )}
                          {serials != 'Выполнен!' && serials == 'В доставке!' && admin.role == 'Бариста' && admin.role == 'Доставщик' && (
                            <td className={style.tabletd}>Далее</td>

                          )}

                        </tr>
                      </thead>
                      {table.map(prod2 => {
                        const user = admins.find(pr => pr.id == prod2.admin_id)
                        // console.log(user)
                        const date3 = new Date(prod2.updatedAt);
                        const date4 = date3.getTime()
                        const date = new Date(date4 + 2 * 60 * 60 * 1000)
                        // Форматирование времени в формате HH:mm
                        const time = ("0" + date.getUTCHours()).slice(-2) + ":" + ("0" + date.getUTCMinutes()).slice(-2);

                        // Форматирование даты в формате YYYY-MM-DD
                        const formattedDate = date.toISOString().slice(0, 10);

                        // Сбор итоговой строки
                        const result = time + " " + formattedDate;
                        const shop = shops.find(pr => pr.id == prod2.shop_id)
                        return (<tbody>

                          <tr>
                            <td className={style.tabletd}>{prod2.id}</td>
                            <td className={style.tabletd}>{shop.name}</td>
                            <td className={style.tabletd}>{user.fullname}</td>
                            <td className={style.tabletd}>
                              <div className={style.cell}>
                                <FcInfo
                                  onClick={() => showList(prod2.list, prod2.id)}
                                />
                              </div>
                            </td>
                            <td className={style.tabletd}><b>{result}</b></td>
                            <td className={style.tabletd}>
                              <div className={style.cell}>
                                <CiFloppyDisk
                                  onClick={() => downloadOrder(prod2.id, shop.name, formattedDate)}
                                />
                              </div>
                            </td>

                            {serials != 'Выполнен!' && (serials == 'Передан на склад!' || serials == 'Собирается!') && admin.role != 'Доставщик' && admin.role != 'Бариста' && (
                              <td className={style.tabletd}>
                                <div className={style.cell}>

                                  <Button text='Далее' background='orange'
                                    onClick={() => submit(updateStatus, prod2.id, serials)}
                                  />
                                </div>
                              </td>
                            )}
                            {serials != 'Выполнен!' && serials == 'В доставке!' && admin.role == 'Бариста' && admin.role == 'Доставщик' && (
                              <td className={style.tabletd}>
                                <div className={style.cell}>

                                  <Button text='Далее' background='orange'
                                    onClick={() => submit(updateStatus, prod2.id, serials)}
                                  />
                                </div>
                              </td>
                            )}


                          </tr>



                        </tbody>)
                      }

                      )}
                    </table>
                  </div>
                </TabPanel>

              </>)
            })}
          </Tabs>
        </div>
        {/* <div className={style.table2}>
          <table className={style.table}>
            <thead className={style.tableth}>

              <tr>
                <td className={style.tabletd}>№</td>
                <td className={style.tabletd}>Роль</td>
                <td className={style.tabletd}>ФИО</td>
                <td className={style.tabletd}>Логин</td>
                <td className={style.tabletd}>Пароль</td>
                <td className={style.tabletd}>email</td>
                <td className={style.tabletd}>Телефон</td>
                <td className={style.tabletd}>ДР</td>
                <td className={style.tabletd}>Дата начала работы</td>
                <td className={style.tabletd}>Действия</td>
              </tr>
            </thead>
            <tbody>
              {/* {admins.map(employee =>
                <tr>
                  <td className={style.tabletd}>{employee.id}</td>
                  <td className={style.tabletd}>{employee.role}</td>
                  <td className={style.tabletd}>{employee.fullname}</td>
                  <td className={style.tabletd}>{employee.login}</td>
                  <td className={style.tabletd}>{employee.pass}</td>
                  <td className={style.tabletd}>{employee.email}</td>
                  <td className={style.tabletd}>{employee.phone}</td>
                  <td className={style.tabletd}>{employee.birthsday}</td>
                  <td className={style.tabletd}>{employee.startdate}</td>
                  <td className={style.tabletd}>
                    <div className={style.cell}>
                      <FcCancel onClick={() => submit(deleteIt, employee.id)} />
                    </div>
                  </td>
                </tr>
              )} */}


        {/* 
            </tbody>
          </table>
        </div> */}

      </div>
      {scladOrderListModal && (
        <ScladOrderListModal
          scladOrderdata={itemlist}
          order_id={orderId}
        />

      )}
      {scladSelectedOrdersModal && (
        <ScladSelectedOrdersModal
          status={statusS}
        />

      )}

      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>

      )}


      {/* <div className={style.sclad_container}>
                <h1 >{shop.name}</h1>





                <div className={style.container}>
                    <div className={style.sidecontainer}>
                        <div className={style.title}>
                            <h2 >Создать заказ</h2>
                        </div>
                        <div className={style.order_container}>
                            <div>
                                Форма
                            </div>
                            <div>
                                Таблица
                            </div>
                        </div>
                    </div>
                    <div className={style.sidecontainer}>
                        <div className={style.title}>
                            <h2 >Заказы</h2>
                        </div>
                        <div className={style.order_container}>
                            <div>
                                Форма
                            </div>
                        </div>
                    </div>

                </div>


            </div> */}





    </>
    );
  }

};

export default AllOrders;
