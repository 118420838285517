import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css

import "react-datepicker/dist/react-datepicker.css";
const EditEmployeesModal = ({ id }) => {

  // console.log(id)

  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const {Notify, editEmployeesModal, setEditEmployeesModal, addEmployeesModal, setAddEmployeesModal, admins, setAdmins, admin } = useContext(QtdContext);
  const employee = admins.find(pr => pr.id === id)
  // console.log(employee)
  const [newValue, setNeValue] = useState({
    id:id,
    login: employee.login,
    pass: employee.pass,
    startdate: employee.startdate,
    email: employee.email,
    tg_id: employee.tg_id,
    phone: employee.phone,
    role: employee.role,
    fullname: employee.fullname,
    birthsday: employee.birthsday,
  })
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container1246422" && setEditEmployeesModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({
    
    login: false,
    pass: false,
    startdate: false,
    email: false,
    tg_id:false,
    phone: false,
    role: false,
    fullname: false,
    birthsday: false,

  })

  const editPrice = async () => {
    try {
      // e.preventDefault()
      console.log(newValue)
      let t = {
        startdate: false,
        login: false,
        pass: false,
        tg_id:false,
        email: false,
        phone: false,
        role: false,
        fullname: false,
        birthsday: false,
      }

      // if (!newValue.startdate) {
      //   t.startdate = `Выберите дану приема на работу!`

      // }
      if (!newValue.login) {
        t.login = `Выберите логин!`

      }
      if (!newValue.pass) {
        t.pass = `Введите пароль!`

      }

      // if (!newValue.email) {
      //   t.email = `Введите email!`

      // }
      // if (!newValue.phone) {
      //   t.phone = `Выберите телефон!`

      // }
      if (!newValue.role) {
        t.role = `Выберите роль!`

      }
      if (!newValue.fullname) {
        t.fullname = `Выберите ФИО!`

      }
      // if (!newValue.birthsday) {
      //   t.birthsday = `Выберите дату рождения!`

      // }

      setErr(t)
      console.log(t)
      console.log(newValue)
      if (
        !t.birthsday &&
        !t.fullname &&
        !t.role &&
        !t.phone &&
        !t.email &&
        !t.pass &&
        !t.login &&
        newValue.birthsday,
        newValue.fullname,
        newValue.role,
        newValue.phone,
        newValue.email,
        newValue.pass,
        newValue.login

      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/admins/edit`,
          { newValue: newValue, admin: admin.id }
        )
        setAdmins(admins.map(pr => pr.id == id ? newProd.data : pr))
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Сотрудник успешно изменен!",
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setEditEmployeesModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      seloading(false)
      console.log(e)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }

  const submit = async () => {
    confirmAlert({
      title: 'Изменить сотрудника',
      message: 'Вы уверены, что хотите изменить сотрудника?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editPrice()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {editEmployeesModal && (
        <div onClick={closeModal} id="container1246422" className={style.modal_container}>

          <div className={style.borders}>
            <div className={style.inputtest}>

              <label >Измените ФИО:</label>

              <input type="text" id="pass" name="pass"
                value={newValue.fullname}
                onChange={(event) => {
                  setNeValue({ ...newValue, fullname: event.target.value });
                }} />
              {err.fullname && (
                <p style={{ color: "red" }}>{err.fullname}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Измените роль:</label>

              <select name="pets" id="prod"
                value={newValue.role}

                onChange={(event) => {
                  setNeValue({ ...newValue, role: event.target.value });
                }}
              >
                <option value="">--Измените категорию--</option>
                <option value="Администратор">Администратор</option>
                <option value="Доставщик">Доставщик</option>
                <option value="Кладовщик">Кладовщик</option>
                <option value="Бариста">Бариста</option>

              </select>
              {err.role && (
                <p style={{ color: "red" }}>{err.role}</p>
              )}
            </div>

            <div className={style.inputtest}>
              <label >Измените логин:</label>

              <input type="text" value={newValue.login} id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, login: event.target.value });
              }} />
              {err.login && (
                <p style={{ color: "red" }}>{err.login}</p>
              )}
            </div>
            <div className={style.inputtest}>
              <label >Измените пароль:</label>

              <input type="text" value={newValue.pass} id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, pass: event.target.value });
              }} />
              {err.pass && (
                <p style={{ color: "red" }}>{err.pass}</p>
              )}
            </div>
            <div className={style.inputtest}>
              <label >Измените телефон:</label>

              <input type="text" value={newValue.phone} id="phone" name="phone" onChange={(event) => {
                setNeValue({ ...newValue, phone: event.target.value });
              }} />
              {err.phone && (
                <p style={{ color: "red" }}>{err.phone}</p>
              )}
            </div>
            <div className={style.inputtest}>
              <label >Измените email:</label>

              <input type="text" id="email" value={newValue.email} name="email" onChange={(event) => {
                setNeValue({ ...newValue, email: event.target.value.toLowerCase() });
              }} />
              {err.email && (
                <p style={{ color: "red" }}>{err.email}</p>
              )}
            </div>
            <div className={style.inputtest}>
              <label >Измените tg_id для уведомлений:</label>

              <input type="text" id="tg_id" value={newValue.tg_id} name="tg_id" 
              onChange={(event) => {
                setNeValue({ ...newValue, tg_id: event.target.value });
              }}
               />
              {/* {err.email && (
                <p style={{ color: "red" }}>{err.email}</p>
              )} */}
            </div>
            {/* <div className={style.inputtest}>

              <label >Измените дату рождения:</label>

              <DatePicker selected={newValue.birthsday} onChange={(date) => { setNeValue({ ...newValue, birthsday: date }) }} />
              {err.birthsday && (
                <p style={{ color: "red" }}>{err.birthsday}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Измените дату начала работы:</label>

              <DatePicker selected={newValue.startdate} onChange={(date) => { setNeValue({ ...newValue, startdate: date }) }} />
              {err.startdate && (
                <p style={{ color: "red" }}>{err.startdate}</p>
              )}
            </div> */}

            <Button
              text="Изменить"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={submit}
            />



          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default EditEmployeesModal;
