import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { CiLink } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import
import LinkProductModal from "./LinkProductModal.js";
import './react-confirm-alert.css'; // Import css


const CreateSRModal = ({ }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAutd } = useContext(QtdContext);

  const { checkAuth,setShops, Notify, saleReportForms, setSaleReportForms, linkProductModal, setLinkProductModal, shops, createSRModal, setCreateSRModal, reciepts, setScladHistory, sclad, setSclad, admin, scladOrders, setScladOrders, setRashodModal, makeOrderModal, setMakeOrderModal } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({

  })
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "c2ontainer12464qq" && setCreateSRModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({
    prod_id: false,
    admin_id: false,
    qty: false,
    shop_id: false,
    step: false,
    stepQty: false

  })

  //Выбор точек
  const something = saleReportForms.map(pr => pr.shops)
  let createdShops = []
  // if(something.length>0){
  for (let i = 0; i < something.length; i++) {
    createdShops = [...createdShops, ...something[i]]
  }
  // } else{
  //   createdShops=shops.map(pr=>pr.id)
  // }


  const [selectShop, setSelectShop] = useState([])

  const [scladcheck, setScladcheck] = useState([])

  useEffect(() => {
    const scld = sclad.filter(pr => (pr.kategory != 'Сироп' && pr.kategory != 'Чай'))
    const scldS = sclad.find(pr => pr.name == 'Сироп - Total')
    const scldT = sclad.find(pr => pr.name == 'Чай - Total')
    let optscld = [...scld]

    if (scldS) {
      optscld = [...optscld, scldS]
    }
    if (scldT) {
      optscld = [...optscld, scldT]
    }

    setScladcheck(optscld.sort((a, b) => a.name > b.name ? 1 : -1))

    const preshops = shops.filter(pr => !createdShops.includes(String(pr.id)))

    const sorted = preshops.sort((a, b) => a.name > b.name ? 1 : -1);
    setSelectShop(sorted)


  }, [])
  const handleRadioChange = (id) => {
    const updatedRadioItems = selectShop.map(item =>
      item.id === id ? { ...item, checked: !item.checked } : item);
    // console.log(id, updatedRadioItems)
    setSelectShop(updatedRadioItems);
  };
  // Выбор рецептов

  const sortedR = reciepts.sort((a, b) => a.name > b.name ? 1 : -1);
  const sortedRrr = sortedR.filter(pr => pr.deleted == false)

  const linked = sortedR.linked
  const [selectreciepts, setSelectreciepts] = useState(sortedRrr)
  const handleRadioChangereciepts = (id) => {
    const updatedRadioItems = selectreciepts.map(item =>
      item.id === id ? { ...item, checked: !item.checked, linked: [] } : item);
    // console.log(id, updatedRadioItems)
    setSelectreciepts(updatedRadioItems);
  };



  const handleRadioChangerecieptsSclad = (id) => {
    const updatedRadioItems = scladcheck.map(item =>
      item.id === id ? { ...item, checked: !item.checked, linked: [] } : item);
    // console.log(id, updatedRadioItems)
    setScladcheck(updatedRadioItems);
  };
  const submit = async () => {
    confirmAlert({
      title: 'Создание Формы Отчета о продажах',
      message: 'Вы уверены, что хотите создать форму заполнения отчета? Вы внимательно проверили данные?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await create()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  const create = async () => {
    try {
      const thisshops = selectShop.filter(pr => pr.checked)
      const shopsids = thisshops.map(sh => sh.id)
      const thisreciepts = selectreciepts.filter(pr => pr.checked)
      const thissclad = scladcheck.filter(pr => pr.checked)
      seloading(true)
      // console.log(newValue)


      const refresh = await checkAuth()
      if (refresh) {
        const newProd = await $api.post(`${BACKURL}/api/salereports/createform`,
          { thisshops: shopsids, thissclad: thissclad, thisreciepts: thisreciepts, admin: admin.id }
        )
        setSaleReportForms(newProd.data.sf)
        setShops(newProd.data.shops)

        setCreateSRModal(false)
        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Форма Отчета о Продажах успешно созданна!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }

      seloading(false)

    } catch (e) {
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }

  };

  const [liked_id, setLiked_id] = useState(null)
  const Linked = (id) => {
    setLiked_id(id)
    setLinkProductModal(true)
  }

  return (
    <>
      {createSRModal && (
        <div onClick={closeModal} id="c2ontainer12464qq" className={style.modal_container}>





          {/* /// */}
          <div className={style.borders}>
            <h5>Выберите точки</h5>
            <div className={style.shopsdiv}>

              <br></br>
              <div className={style.shopsdivcoffees}>

                {selectShop.map((shop, index) => (

                  <div className={style.form_radio_btn}
                    onClick={() => handleRadioChange(shop.id)}
                  >
                    <label htmlFor={`radio_${shop.id}`}
                      style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                    </label>
                    <input type="radio" name="radio_group"
                      checked={shop.checked}
                      style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                    // className={style.form_radio_btn}
                    />
                  </div>
                )
                )}
              </div>
            </div>

            <br></br>


            <div className={style.shopsdiv}>
              <h5>Выберите продукты c счетчиком в кофемащине</h5>
              <br></br>
              <div className={style.shopsdivcoffees} style={{ flexDirection: 'column' }}>
                {selectreciepts.map((shop, index) => {
                  if (shop.kategory == 'Кофемашина') {
                    return (<>
                      <div className={style.coffeess}>
                        <div className={style.form_radio_btn}


                        >

                          <label htmlFor={`radio_${shop.id}`}
                            onClick={() => handleRadioChangereciepts(shop.id)}
                            style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                          </label>
                          <input type="radio" name="radio_group"
                            checked={shop.checked}
                            style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                          // className={style.form_radio_btn}
                          />
                        </div>
                        <div>
                          {shop.checked && (
                            <CiLink style={{ fontSize: '50px' }} onClick={() => Linked(shop.id)}>

                            </CiLink>
                          )}
                        </div>
                        <div>
                          {shop.linked && (<>
                            {shop.linked.length > 0 && (
                              <div>
                                {shop.linked.map(item => {
                                  const linkedcoff = reciepts.find(pr => pr.id == item)
                                  if (linkedcoff) {
                                    return (
                                      <div className={style.form_radio_btn}


                                      >

                                        <label htmlFor={`radio_${shop.id}`}
                                          style={{ background: '#BCEDF6' }}>{linkedcoff.name}
                                        </label>
                                        <input type="radio" name="radio_group"
                                          // checked={shop.checked}
                                          style={{ background: '#BCEDF6' }}
                                        // className={style.form_radio_btn}
                                        />
                                      </div>
                                    )
                                  }
                                })}
                              </div>
                            )}
                          </>

                          )}

                        </div>
                      </div>
                    </>




                    )
                  }


                })}
              </div>
            </div>
            <br></br>

            <h5>Выберите напитики без кофе</h5>
            <div className={style.shopsdiv}>

              <br></br>
              <div className={style.shopsdivcoffees}>
                {selectreciepts.map((shop, index) => {
                  if (shop.kategory == 'Напитки без кофе') {
                    return (

                      <div className={style.form_radio_btn}
                        onClick={() => handleRadioChangereciepts(shop.id)}
                      >
                        <label htmlFor={`radio_${shop.id}`}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                        </label>
                        <input type="radio" name="radio_group"
                          checked={shop.checked}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                        // className={style.form_radio_btn}
                        />
                      </div>

                    )
                  }


                })}
              </div>
            </div>
            <br></br>


            <div className={style.shopsdiv}>
              <h5>Выберите Чаи</h5>
              <br></br>
              <div className={style.shopsdivcoffees}>

                {selectreciepts.map((shop, index) => {
                  if (shop.kategory == 'Чай') {
                    return (

                      <div className={style.form_radio_btn}
                        onClick={() => handleRadioChangereciepts(shop.id)}
                      >
                        <label htmlFor={`radio_${shop.id}`}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                        </label>
                        <input type="radio" name="radio_group"
                          checked={shop.checked}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                        // className={style.form_radio_btn}
                        />
                      </div>

                    )
                  }


                })}
              </div>
            </div>
            <br></br>


            <div className={style.shopsdiv}>
              <h5>Выберите Сиропы</h5>
              <br></br>
              <div className={style.shopsdivcoffees}>

                {selectreciepts.map((shop, index) => {
                  if (shop.kategory == 'Сироп') {
                    return (

                      <div className={style.form_radio_btn}
                        onClick={() => handleRadioChangereciepts(shop.id)}
                      >
                        <label htmlFor={`radio_${shop.id}`}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                        </label>
                        <input type="radio" name="radio_group"
                          checked={shop.checked}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                        // className={style.form_radio_btn}
                        />
                      </div>

                    )
                  }


                })}
              </div>
            </div>

            <div className={style.shopsdiv}>
              <h5>Выберите Прочее</h5>
              <br></br>
              <div className={style.shopsdivcoffees}>

                {selectreciepts.map((shop, index) => {
                  if (shop.kategory == 'Прочее') {
                    return (

                      <div className={style.form_radio_btn}
                        onClick={() => handleRadioChangereciepts(shop.id)}
                      >
                        <label htmlFor={`radio_${shop.id}`}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                        </label>
                        <input type="radio" name="radio_group"
                          checked={shop.checked}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                        // className={style.form_radio_btn}
                        />
                      </div>

                    )
                  }


                })}
              </div>
            </div>

            <h5>Подсчет остатков</h5>
            <div className={style.shopsdiv}>

              <br></br>
              <div className={style.shopsdivcoffees}>
                {scladcheck.map((shop, index) => {
                  // if (shop.kategory == 'Напитки без кофе') {
                  return (

                    <div className={style.form_radio_btn}
                      onClick={() => handleRadioChangerecieptsSclad(shop.id)}
                    >
                      <label htmlFor={`radio_${shop.id}`}
                        style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                      </label>
                      <input type="radio" name="radio_group"
                        checked={shop.checked}
                        style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                      // className={style.form_radio_btn}
                      />
                    </div>

                  )
                  // }


                })}
              </div>
            </div>
            <br></br>

            <Button
              text="Создать"
              background="var(--primary-color)"
              color="white"
              width="250px"
              onClick={submit}
            />




          </div>

          {linkProductModal && (
            <LinkProductModal
              id={liked_id}


              selectreciepts={selectreciepts}
              setSelectreciepts={setSelectreciepts}>

            </LinkProductModal>
          )}
        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
      {/* {linkProductModal&&(
        <LinkProductModal
        id={liked_id}>

        </LinkProductModal>
      )} */}
    </>
  );
};

export default CreateSRModal;
