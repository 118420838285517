import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import { CiCirclePlus } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import

const LinkProductModal = ({ id, selectreciepts, setSelectreciepts }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAutd } = useContext(QtdContext);

  const { linkProductModal, setLinkProductModal, shops, createSRModal, setCreateSRModal, reciepts, setScladHistory, sclad, setSclad, admin, scladOrders, setScladOrders, setRashodModal, makeOrderModal, setMakeOrderModal } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({

  })
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "xwxwewx" && setLinkProductModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({
    prod_id: false,
    admin_id: false,
    qty: false,
    shop_id: false,
    step: false,
    stepQty: false

  })
console.log(selectreciepts)
  const mainReciept = selectreciepts.find(pr => pr.id == id)

  // const handleRadioChange = (id) => {
  //   const updatedRadioItems = selectShop.map(item =>
  //     item.id === id ? { ...item, checked: !item.checked } : item);
  //   console.log(id, updatedRadioItems)
  //   setSelectreciepts(updatedRadioItems);
  // };
  // Выбор рецептов

  // const sortedR = reciepts.sort((a, b) => a.name > b.name ? 1 : -1);
  // const [selectreciepts, setSelectreciepts] = useState(sortedR)


  const handleRadioChangereciepts = (id) => {
    // if()
    let linked = mainReciept.linked
    let unique = linked.map((acc) => acc)
    console.log('=====',id, unique)
    if (unique.includes(id)) {
      linked = linked.filter(sitems => sitems != id)
    } else {
      const rec = reciepts.find(pr => pr.id == id)

      // const reciept
      linked.push(rec.id)
    }
    //  mainReciept.linked = linked .
    const updatedRadioItems = selectreciepts.map(item =>
      item.id === mainReciept.id ? { ...item, linked: linked } : item);
    // console.log(id, updatedRadioItems)
    setSelectreciepts(updatedRadioItems);
  };

  // const create = () => {
  //   const thisshops = selectShop.filter(pr => pr.checked)
  //   console.log(thisshops)
  // };
  const what = reciepts.filter(pr => pr.id != id)
  const sortedR = what.sort((a, b) => a.name > b.name ? 1 : -1);
  return (
    <>
      {linkProductModal && (
        <div onClick={closeModal} id="xwxwewx" className={style.modal_container}>





          {/* /// */}
          <div className={style.borders} style={{height: 'auto'}}>

            <h5>Выберите продукты c счетчиком в кофемащине</h5>


            <div className={style.shopsdiv}>

              <br></br>
              {sortedR.map((shop, index) => {
                if (shop.kategory == 'Кофемашина') {
                  return (
                    <div>
                      <div className={style.form_radio_btn}
                        onClick={() => handleRadioChangereciepts(shop.id)}
                      >
                        <label htmlFor={`radio_${shop.id}`}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                        </label>
                        <input type="radio" name="radio_group"
                          checked={shop.checked}
                          style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                        // onChange={(event) => handleRadioChangereciepts(shop.id, event.target.value)}

                        // className={style.form_radio_btn}
                        />
                      </div>


                    </div>
                  )
                }


              })}
            </div>
            <div className={style.shopsdiv}>

              <br></br>
              {mainReciept.linked.map((id, index) => {
                const reciept = selectreciepts.find(pr => pr.id == id)
                return (
                  <div>
                    <div className={style.form_radio_btn}
                      onClick={() => handleRadioChangereciepts(reciept.id)}
                    >
                      <label htmlFor={`radio_${reciept.id}`}
                        style={{ background: '#ffe0a6' }}>{reciept.name}
                      </label>
                      <input type="radio" name="radio_group"
                      //  checked={shop.checked}
                      //  style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                      // className={style.form_radio_btn}
                      />
                    </div>


                  </div>
                )



              })}
            </div>

            <Button
              text="Готово"
              background="var(--primary-color)"
              color="white"
              width="250px"
              onClick={()=> setLinkProductModal(false)}
            />




          </div>


        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default LinkProductModal;
