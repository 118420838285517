import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { FcEngineering, FcCancel } from "react-icons/fc";
import "react-datepicker/dist/react-datepicker.css";
// import reciepts from "../../../../citycoffee_back/src/models/reciepts.js";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css

const AddRecieptListModal = ({ order_id }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [err, setErr] = useState('')

  const { Notify,setReciepts,reciepts,setRecieptListModal,addRecieptOrderListModal, setAddRecieptOrderListModal,setSclad, setScladOrderListModal, setEditScladOrderListItemModal, addScladOrderListItemModal, setAddScladOrderListItemModal, sclad, setScladOrders, scladOrders, admin } = useContext(QtdContext);
  const closeModal2 = ({ target }) => {
    // console.log(target)
    target.id === "gjh2hkkq911w" && setAddRecieptOrderListModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  // console.log('order_id, prod_id', order_id)
  // const prod = sclad.find(pr => pr.id == prod_id)

  const editIt = async () => {
    // e.preventDefault()
    try {
      // console.log(newValue)

      const newProd = await $api.post(`${BACKURL}/api/reciepts/additem`,
          { newValue: newValue, admin: admin.id }
        )
       
        setReciepts(newProd.data)
        seloading(false)
        setAddRecieptOrderListModal(false)
        setRecieptListModal(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Ингридиент успешно добавлен в продукт!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
      // let t = ''
      // if (newValue <= 0 || newValue == '') {
      //   t = 'Выберите количество!'
      // }
      // setErr(t)
      // if (!t && newValue) {
      //   seloading(true)
      //   console.log('ask, ', order_id, prod_id, newValue)

      //   const newProd = await $api.post(`${BACKURL}/api/scladorder/editqty`,
      //     { order_id, prod_id, newValue: newValue, admin: admin.id }
      //   )
      //   setSclad(
      //     newProd.body.scladall
      //   )
      //   setScladOrders(scladOrders.map(pr => {
      //     if (pr.id == newProd.body.scladOrder.id) {
      //       return newProd.body.scladOrder
      //     } else {
      //       return pr
      //     }
      //   }))
      //   seloading(false)

      //   setAddScladOrderListItemModal(false)
      // }
    } catch (e) {
      // console.log(e)
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }
  }
  const submit = async () => {
    confirmAlert({
      title: 'Добавить ингридиент в продукт',
      message: 'Вы уверены, что хотите ингридиент в продукт?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editIt()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  const [newValue, setNeValue] = useState({
    admin_id: admin.id,
    order_id,
    // list: '',
    prod_id: '',
    qty: '',
    step: '',
    stepQty: ''
  })
  const setProd = (prod_id) => {
    const thisProd = sclad.find(item => item.id == prod_id)
    const order = reciepts.find(pr => pr.id == order_id)
    const list = order.reciept.map(pr => JSON.parse(pr))
    const result = list.find(item => item.prod_id == thisProd.id)
    // console.log(thisProd, !result)
    if (!result) {
      setNeValue({ ...newValue, prod_id: prod_id, step: thisProd.step, stepQty: thisProd.qty })

    } else {
      setErr({ prod_id: 'Данный товар уже был выбран!' })
    }
    // if()

  }
  const setQty = (qty) => {
    const prod = sclad.find(pr => pr.id == newValue.prod_id)

    // if (prod.scldqty - prod.scldbron - qty >= 0) {
      setNeValue({ ...newValue, qty: qty });

    // } else {
    //   setErr({ qty: `Всего на складе доступно ${prod.scldqty - prod.scldbron}${prod.mnt} ${prod.name}!` })
    // }

  }
  const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory)));
  // console.log(uniqueSerials, sclad)
  function checkDuplicateIds(array1) {
    const order = reciepts.find(pr => pr.id == order_id)
    const list = order.reciept.map(pr => JSON.parse(pr))
    const ids1 = array1.map(obj => obj.id);
    const ids2 = list.map(obj => obj.prod_id);

    for (let i = 0; i < ids1.length; i++) {
      if (ids2.includes(ids1[i])) {
        return true;
      }
    }

    return false;
  }
  // const qeroFilter = sclad.filter(pr => ((Number(pr.scldqty) - Number(pr.scldbron) > 0) && !checkDuplicateIds(sclad)))
  const sorted = sclad.sort((a, b) => a.name > b.name ? 1 : -1);
  return (
    <>
      {addRecieptOrderListModal && (
        <div onClick={closeModal2} id="gjh2hkkq911w" className={style.modal_container}>

          <div className={style.borders}>
            <div className={style.inputtest}>

              <label >Выберите товар:</label>

              <select name="pets" id="prod"
                value={newValue.prod_id}
                onChange={(event) => {
                  setProd(event.target.value)

                }}
              >
                <option value="">--Выберите товар--</option>

                {uniqueSerials.map(serials => {

                  const table = sorted.filter(prod => prod.kategory === serials)

                  return (<>
                    <optgroup label={`${serials}`}>
                      {table.map(prod => (
                        <option value={`${prod.id}`}>{prod.name}</option>

                      ))}
                    </optgroup></>
                  )
                }
                )}


              </select>
              {err.prod_id && (
                <p style={{ color: "red" }}>{err.prod_id}</p>
              )}
            </div>

            <div className={style.inputtest}>

              <label >Выберите количество:</label>

              <input type="number" id="pass" name="pass" onChange={(event) => {
                setQty(event.target.value)

              }} />
              {err.qty && (
                <p style={{ color: "red" }}>{err.qty}</p>
              )}
            </div>



            <Button
              text="Добавить"
              background="var(--primary-color)"
              color="white"
              width="100%"
              onClick={submit}
            />


          </div>

        </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default AddRecieptListModal;
