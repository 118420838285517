import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css

import "react-datepicker/dist/react-datepicker.css";
const EditRecieptModal = ({ id }) => {

  // console.log(id)

  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { Notify,editRecieptModal, setEditRecieptModal, reciepts, setReciepts, addEmployeesModal, setAddEmployeesModal, admins, setAdmins, admin } = useContext(QtdContext);
  const employee = reciepts.find(pr => pr.id === id)
  // console.log(employee)
  const [newValue, setNeValue] = useState({
    id: id,
    name: employee.name,
    price: employee.price,
    list: employee.list,
    kategory: employee.kategory,

  })
  console.log(newValue)
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "co2ntainer1246422" && setEditRecieptModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({

    name: false,
    price: false,
    list: false,
    kategory: false,


  })

  const editPrice = async () => {
    try {
      // e.preventDefault()
      // console.log(newValue)
      let t = {
        startdate: false,
        login: false,
        pass: false,
        tg_id: false,
        email: false,
        phone: false,
        role: false,
        fullname: false,
        birthsday: false,
      }

      // if (!newValue.startdate) {
      //   t.startdate = `Выберите дану приема на работу!`

      // }
      if (!newValue.name) {
        t.name = `Выберите наименование!`

      }
      if (!newValue.price) {
        t.price = `Введите цену!`

      }
      if (!newValue.list) {
        t.list = `Введите ингридиенты!`

      }

      setErr(t)
      if (
        !t.name &&
        !t.price &&
        !t.list &&

        newValue.list,
        newValue.price,
        newValue.name


      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/reciepts/edit`,
          { newValue: newValue, admin: admin.id }
        )
        setReciepts(reciepts.map(pr => pr.id == id ? newProd.data : pr))
        // setServOrders(newPosts)
        // setSclad(newPosts)

        // //Проверь правильность данных тут
        // setServerProd(newPosts)
        // console.log(newProd.data)
        // setModalInfo(false)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Продукт успешно обновлен!",
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        })
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setEditRecieptModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }

  const submit = async () => {
    confirmAlert({
      title: 'Изменить товар',
      message: 'Вы уверены, что хотите изменить товар?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editPrice()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {editRecieptModal && (
        <div onClick={closeModal} id="co2ntainer1246422" className={style.modal_container}>

          <div className={style.borders}>
            <div className={style.inputtest}>

              <label >Измените Наименование:</label>

              <input type="text" id="pass" name="pass"
                value={newValue.name}
                onChange={(event) => {
                  setNeValue({ ...newValue, name: event.target.value });
                }} />
              {err.name && (
                <p style={{ color: "red" }}>{err.name}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Выберите категорию:</label>

              <select name="pets" id="prod"
              value={newValue.kategory}
                onChange={(event) => {
                  setNeValue({ ...newValue, kategory: event.target.value });
                }}
              >
                <option value="">--Выберите категорию--</option>
                <option value="Кофемашина">Кофемашина</option>
                <option value="Чай">Чай</option>
                <option value="Напитки без кофе">Напитки без кофе</option>

                <option value="Сироп">Сироп</option>
                <option value="Прочее">Прочее</option>

              </select>
              {err.kategory && (
                <p style={{ color: "red" }}>{err.kategory}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Измените Цену:</label>

              <input type="number" id="pass" name="pass"
                value={newValue.price}
                onChange={(event) => {
                  setNeValue({ ...newValue, price: event.target.value });
                }} />
              {err.price && (
                <p style={{ color: "red" }}>{err.price}</p>
              )}
            </div>


            <Button
              text="Изменить"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={submit}
            />



          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default EditRecieptModal;
