import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import './react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert'; // Import

import "react-datepicker/dist/react-datepicker.css";
// import shops from "../../../../../citycoffee_back/src/models/shops.js";
const CreateCoffeeMashinsModal = ({ }) => {


  // const produtosCart = JSON.parse(localStorage.getItem("pet"));
  // const { setSclad,setServerProd, auth, setAuth } = useContext(QtdContext);

  const { Notify,coffeeMashins,setCoffeeMashins, reciepts, shops, createCoffeeMashins, setCreateCoffeeMashins, addEmployeesModal, setAddEmployeesModal, admins, setAdmins, admin } = useContext(QtdContext);
  const [newValue, setNeValue] = useState({})
  const [loading, seloading] = useState(false)
  const coffeReciepts = reciepts.filter(pr => (pr.kategory == 'Кофемашина' && pr.deleted == false))
  const sorted = coffeReciepts.sort((a, b) => a.name > b.name ? 1 : -1);

  const [selectedReciepts, setSelectedReciepts] = useState(sorted)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12464" && setCreateCoffeeMashins(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const handleRadioChange = (id) => {
    const updatedRadioItems = selectedReciepts.map(item =>
      item.id === id ? { ...item, checked: !item.checked } : item);
    // console.log(id, updatedRadioItems)
    setSelectedReciepts(updatedRadioItems);
  };
  const CounterChange = (id, value) => {
    const updatedRadioItems = selectedReciepts.map(item =>
      item.id === id ? { ...item, counter: value } : item);
    // console.log(id, updatedRadioItems)
    setSelectedReciepts(updatedRadioItems);
  };
  const [err, setErr] = useState({
    login: false,
    pass: false,
    startdate: false,
    email: false,
    phone: false,
    role: false,
    fullname: false,
    birthsday: false,

  })
  const submit = async () => {
    confirmAlert({
      title: 'Создание кофемашины',
      message: 'Вы уверены, что хотите создать кофемашину? Вы внимательно проверили данные?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await editPrice()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  const editPrice = async () => {
    try {
      // e.preventDefault()

      let t = {
        name: false,
        login: false,
        pass: false,
        email: false,
        phone: false,
        role: false,
        fullname: false,
        birthsday: false,
      }

      if (!newValue.name) {
        t.name = `Введите модель кофемашины!`

      }
      if (!newValue.shop_id) {
        t.shop_id = `Выберите точку!`

      }


      setErr(t)
      if (
        !t.name &&
        !t.shop_id &&

        newValue.name &&
        newValue.shop_id


      ) {
        seloading(true)
        //   // console.log(newValue)
        const r = selectedReciepts.filter(pr => pr.checked)
        const newProd = await $api.post(`${BACKURL}/api/coffeemashins/create`,
          { newValue: newValue, reciepts: r, admin: admin.id }
        )
        setCoffeeMashins([...coffeeMashins, newProd.data])
        setCreateCoffeeMashins(false)
        //   setAdmins([...admins, newProd.data])
        //   // setServOrders(newPosts)
        //   // setSclad(newPosts)

        //   // //Проверь правильность данных тут
        //   // setServerProd(newPosts)
        //   // console.log(newProd.data)
        //   // setModalInfo(false)
        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Кофемашина успешно создана!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        //   // localStorage.setItem("petNew", JSON.stringify(newPosts));
        //   setAddEmployeesModal(false)
        //   // alert('Добавлено!')
      }

      // console.log(newValue, selectedReciepts.filter(pr => pr.checked))

    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }
  const availa = coffeeMashins.map(pr=>{return Number(pr.shop_id)})
  console.log('availa', availa)
  const availableshops = shops.filter(pr=>!availa.includes(pr.id))

  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {createCoffeeMashins && (
        <div onClick={closeModal} id="container12464" className={style.modal_container}>

<div className={style.borders} style={{height:'80%'}}>
            <div className={style.inputtest}>

              <label > Введите модель:</label>

              <input type="text" id="pass" name="pass" onChange={(event) => {
                setNeValue({ ...newValue, name: event.target.value });
              }} />
              {err.name && (
                <p style={{ color: "red" }}>{err.name}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Выберите точку:</label>

              <select name="pets" id="prod"
                onChange={(event) => {
                  setNeValue({ ...newValue, shop_id: event.target.value });
                }}
              >
                <option value="">--Выберите точку--</option>

                {availableshops.map(shop => {
                  return (
                    <option value={shop.id}>{shop.name}</option>

                  )
                })}

              </select>
              {err.shop_id && (
                <p style={{ color: "red" }}>{err.shop_id}</p>
              )}
            </div>

            <div className={style.inputtest}>
              <h5>Выберите счетчик</h5>
              <div className={style.shopsdiv} style={{flexDirection:'column'}}>

                <br></br>
                {selectedReciepts.map((shop, index) => (
                  <>
                  <div className={style.coffees}>
                    <div className={style.form_radio_btn}
                      onClick={() => handleRadioChange(shop.id)}
                    >
                      <label htmlFor={`radio_${shop.id}`}
                        style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}>{shop.name}
                      </label>
                      <input type="radio" name="radio_group"
                        checked={shop.checked}
                        style={{ background: shop.checked ? '#ffe0a6' : '#efefef' }}
                      // className={style.form_radio_btn}
                      />
                      </div>
                    </div>

                    {shop.checked && (<>
                      <label > Введите показание счетчика:</label>

                      <input type="text" id="pass" name="pass"
                        onChange={(event) => CounterChange(shop.id, event.target.value)
                        }
                      />
                      {err.fullname && (
                        <p style={{ color: "red" }}>{err.fullname}</p>
                      )}
                    </>
                    )}
                  </>



                )
                )}
              </div>






            </div>
            <Button
              text="Создать"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={submit}
            />
          </div>

        </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default CreateCoffeeMashinsModal;
